/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import React,{ useState, useEffect } from 'react';
import 'survey-analytics/survey.analytics.min.css';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';
import {getResults2,getOneSurvey2 } from '../../Api';
import {  useParams,Link  } from 'react-router-dom';

const SurveyAnalyticspage=()=>{
  const [schema,setSchema]=useState(null)
const [results,setResults]=useState([])
const {survey,ticket}=useParams([])
  const [loading, setLoading] = useState(false);
 

const fetchOneSurvey = async () => {
  setLoading(true)
  try {
    getOneSurvey2(survey).then(data=>{
        
            setSchema(data)
            // console.log({rs,data});
            //  console.log({results,schema});
          
             setLoading(false)
    });
  

  //  
  } catch (error) {
    setLoading(false)
    console.log(error)
  }}
const fetchResults = async () => {
  setLoading(true)
  console.log(ticket)
  try {
    getResults2(survey).then(data=>{
      const rs=data;
      
          setResults([rs[0]?.response])
fetchOneSurvey()
          
    });
  

  //  
  } catch (error) {
       setLoading(false)
    console.log(error)
  }}
useEffect(()=>{
  fetchResults()
  

  },[])
    return (

              <div>{results.length!=0 && schema && <App surveyJson={schema} surveyResults={results}/>
                }
       {loading &&  <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',color:'white',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)',zIndex:1000}}>
<strong>Loading...</strong>

        </div>}
              </div>
             
              
              )
}




const vizPanelOptions = {
  allowHideQuestions: false
}

const App=({surveyJson,surveyResults})=>{

  const [survey, setSurvey] = useState(null);

  const [vizPanel, setVizPanel] = useState(null);
  if (!survey) {
    const survey = new Model(surveyJson);
    setSurvey(survey);
  }

  if (!vizPanel && !!survey) {
    const vizPanel = new VisualizationPanel(
      survey.getAllQuestions(),
      surveyResults,
      vizPanelOptions
    );
    vizPanel.showToolbar = false;
    setVizPanel(vizPanel);
  }

  useEffect(() => {
    vizPanel.render("surveyVizPanel");
    return () => {
      document.getElementById("surveyVizPanel").innerHTML = "";
    }
  }, [vizPanel]);



  
  return (
    <div >
   
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
<Link className="navbar-brand" to="/survey/admin"> Konnect (Survey Analytics) </Link>
    <div className="ml-auto">
   {/* <button 
  
     className="btn btn-success ml-auto" type="button">Download Results</button>   */}
    {/* <button
   onClick={()=>{  window.location.pathname=window.location.pathname.split('/')[0]+'/survey/admin';}}
     className="btn btn-warning ml-2" type="button">Exit</button> */}

  </div>  </nav> 
  <p style={{padding:10,paddingTop:50}}>

<h1>{surveyJson?.title}</h1>
<p style={{color:'gray'}}>{surveyJson?.description}</p>

</p>
   <div  id="surveyVizPanel" />
   {/* <div style={{position:'absolute',left:0,top:0,height:'100vh',width:'100%',zIndex:30,background:'rgba(255,255,0,0.2)'}}></div> */}
    </div>

  );
}
export default SurveyAnalyticspage;