import React, { useState, useEffect } from "react";
import { postEventApplication_3 } from "../Api";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import ReactHtmlParser from 'react-html-parser';
export default function EventApplication() {
  const [name, setName] = useState("User");
  const [phone, setPhone] = useState("none");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [region, setRegion] = useState("not specified");
  const [regionOthers, setRegionOthers] = useState("");
  const [open_quiz_1, setOpen_quiz_1] = useState("");
  const [open_quiz_2, setOpen_quiz_2] = useState("");
  const [modalImage, setModalImage] = useState("");
  const [hasVoted, setHasVoted] = useState(false); // Track if the user has voted

  const { id } = useParams();
  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const fingerprint = result.visitorId;

      // Check if this fingerprint has voted before
      const votedKey = `${fingerprint}`;
      if (localStorage.getItem(votedKey)) {
        setHasVoted(true);
      }
      // console.log(votedKey)
      setOpen_quiz_2(votedKey)
    };

    loadFingerprint();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("title", id);
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("residency", region);
    formData.append("residency_other", regionOthers);
    formData.append("open_quiz_1", open_quiz_1);
    formData.append("open_quiz_2", open_quiz_2);
    try {
      const result = await postEventApplication_3(formData);
      if (result) {
        setSuccess(true);
      
        const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId;

    const votedKey = `${fingerprint}`;
    localStorage.setItem(votedKey, "true");
    setHasVoted(true);

        setName("");
        setPhone("");
        setRegion("");
        setRegionOthers("");
        setOpen_quiz_1("");
      }
    } catch (error) {
      console.error("Error submitting application:", error);
    } finally {
      setLoading(false);
    }
  };


  const quizOptions = [
    {
      id: "1",
      story:
        `<h4>1. Samson</h4>
<div><strong>How did you and your partner first meet, and what was your first impression of each other:</strong></div>
<div>I was living with my elder sister and he was our neighbour he loved my siz kids a lot and it was a thing for me that made me started noticing him and my impression on him was how he loved those kids I knew he can be a good dad one day</div>
<div><strong>What's the most romantic or thoughtful thing your partner has ever done for you:</strong></div>
<div>I can say small things matter to me because he wasn't that well established at that time we started dating but he did his best to make me happy one time he suprised me he took me to Uhuru park a small picnic was the best thing and I 😍 loved it alot we enjoyed the day</div>
<div><strong>Whats the funniest or most embarrassing moment you’ve experienced as a couple:</strong></div>
<div>After giving birth, my body changed I used to be a petite as u 😂 call them nowadays, but things changed after birth kinda I started having my imagination . He doesn't like me because I AM fat and I have a lot of appetite and how my body changed</div>
<div><strong>If you could describe your relationship in one movie title, what would it be and why:</strong></div>
<div>WAR ROOM:The way Tony and his wife fought for their love 💕</div>
<div><strong>Whats one lesson you’ve learned from your partner that has changed your life:</strong></div>
<div>Patience is a virtue I've learned that bcoz we started from scratch and now where we began things have really changed for the best</div>
<div><strong>How do you keep the spark alive in your relationship after all these years:</strong></div>
<div>The love we have for each other, seeing our 4 children now adults,and our 1-year-old grandchild</div>
<div><strong>What's one dream or goal you and your partner are working towards together:</strong></div>
<div>Before aging enough, we dream of building and owning our own place and having a business of ours</div>
<div><strong>If you could relive one day with your partner, which day would it be and why:</strong></div>
<div>The day we went to my parents' place to tell them about our love and how we would love to start living together it was the best day of my life.</div>
<div><strong>Why do you and your partner deserve a romantic Valentine’s dinner with Konnect:</strong></div>
<div>Marriage has its ups and down sometimes.We would love to cool of just two of us to remind ourselves about the past to keep the love we share warm. But due to lack of enough money some things like outings we haven't being doing. So it can be a good thing if we can have that chance</div>`,
    },
    {
      id: "2",
     story:`<h4>2. Maureen</h4>
<div><strong>How did you and your partner first meet, and what was your first impression of each other:</strong></div>
<div>We met a a bus stop while he was the conductor of a matatu. The way he smiled while pursuing me to enter that matatu, when he called me "Madam twende tao" oh my! I was just carried away. Love at 1st sight. Following days i was ever late to work as i waited for that particular matatu just so i could see him again. 16 years later he is still the love of my life.</div>
<div><strong>What's the most romantic or thoughtful thing your partner has ever done for you:</strong></div>
<div>Nursing my cs wound when i couldn't even move. Washing and massaging my feet when i couldn't even walk.</div>
<div><strong>Whats the funniest or most embarrassing moment you’ve experienced as a couple:</strong></div>
<div>Queefing the 1st night at his house. Gosh i was embarrassed until i learnt its normal to queef.</div>
<div><strong>If you could describe your relationship in one movie title, what would it be and why:</strong></div>
<div>One day they'll write a story about us</div>
<div><strong>Whats one lesson you’ve learned from your partner that has changed your life:</strong></div>
<div>Everyday will pass. Experience every moment and learn something from it</div>
<div><strong>How do you keep the spark alive in your relationship after all these years:</strong></div>
<div>Dinner dates twice per month out of the house and if not possible due to unavoidable circumstances, dedicate a call that each can speak, be heard and leave the conversation fully satisfied.</div>
<div><strong>What's one dream or goal you and your partner are working towards together:</strong></div>
<div>Building a permanent house back at home to retire to late in life</div>
<div><strong>If you could relive one day with your partner, which day would it be and why:</strong></div>
<div>When he walked from the hospital room carrying our new born son. That was one moment I always appreciate.</div>
<div><strong>Why do you and your partner deserve a romantic Valentine’s dinner with Konnect:</strong></div>
<div>To just reconnect and remind ourselves of how these experiences impact our love story</div>`
           },
    {
      id: "3",
      story:`
<h4>3. Elvis</h4>
<div><strong>How did you and your partner first meet, and what was your first impression of each other:</strong></div>
<div>There is a tym I was traveling..so on my left she sat a beautiful and lovely lady,,but she looked so stressed and hopeless..so as a man I greated her and asked her what's up...she once tried to be shy but I insisted ans at the end she opened up ,,she had lost her bag and everything was on it..so even she was unable to pay her fare so I decided to help her out.. I sorted her fare and even gave her something to escort her till she reaches hone..she was so happy and I was grateful too coz I knew I had relived the stressful situation she was in... I gave her my number and asked her to give me a call when she reaches home.. I eagerly waited for her call..after a while she called and thanked me for everything..we talked more as tym goes until when we new each other well that's when I decided to open up to her on how I feel and crush on her...the rest was history because shes my partner till now</div>
<div><strong>What's the most romantic or thoughtful thing your partner has ever done for you:</strong></div>
<div>I remember there is a tym I lost my job and my house was almost to be locked ..but these Gid sent lady helped me a lot the only savings she had she paid my areas and helped me to secure another job</div>
<div><strong>Whats the funniest or most embarrassing moment you’ve experienced as a couple:</strong></div>
<div>There us a tym she asked me about my past relationship and it happened I lied to her about my ex so I tried to lie to her only to realize someone had told her earlier so she was so mad at me and I felt I had wronged her .. I felt bad to cool her down it took tym coz I had lost the trust again</div>
<div><strong>If you could describe your relationship in one movie title, what would it be and why:</strong></div>
<div>The love Story because we have been through a lot ups and down many people even advised her leave me but she was always on my side and trusted in me...we have made great steps and we are still pressing on.. it feels good having the right partner in life</div>
<div><strong>Whats one lesson you’ve learned from your partner that has changed your life:</strong></div>
<div>She has helped me the virtue of helping..when you have the ability to help someone don't hesitate because God will reward you most and he gave me a beautiful partner I have now</div>
<div><strong>How do you keep the spark alive in your relationship after all these years:</strong></div>
<div>Loving and trusting each other.. supporting each other is the key too</div>
<div><strong>What's one dream or goal you and your partner are working towards together:</strong></div>
<div>Becoming greatest entrepreneur's because my partner believes in becoming her own boss so each and every day we save the little we get extra coz a journey of a thousand mile starts with the single mile</div>
<div><strong>If you could relive one day with your partner, which day would it be and why:</strong></div>
<div>The first day I met while she was stranded and hopeless but I was there for her and relieved her stress... I'm always happy because is the day I met the love of my life</div>
<div><strong>Why do you and your partner deserve a romantic Valentine’s dinner with Konnect:</strong></div>
<div>She has done a lot to me and I have never surprised her in a good way so these will be a chance to appreciate for the tym she has been with me 💓 I will really appreciate it if u offer me these dinner date</div>`
     },
    {
      id: "4",
       story:`<h4>4. George</h4>
<div><strong>How did you and your partner first meet, and what was your first impression of each other:</strong></div>
<div>At work place</div>
<div>Her maturity humbleness and desire to know more about me</div>
<div><strong>What's the most romantic or thoughtful thing your partner has ever done for you:</strong></div>
<div>Washing my boxers and buying me shoes</div>
<div><strong>Whats the funniest or most embarrassing moment you’ve experienced as a couple:</strong></div>
<div>The fact that it became apparent that we were going to have sex with the knowledge of our parents . Something that I never imagined</div>
<div><strong>If you could describe your relationship in one movie title, what would it be and why:</strong></div>
<div>Romeo and Juliet</div>
<div>We have never had any negative thoughts despite challenges</div>
<div><strong>Whats one lesson you’ve learned from your partner that has changed your life:</strong></div>
<div>She rarely give up and hardworking</div>
<div><strong>How do you keep the spark alive in your relationship after all these years:</strong></div>
<div>Treating her like my sister would want to be treated with her husband</div>
<div><strong>Whats one dream or goal you and your partner are working towards together:</strong></div>
<div>Starting a business and owning rentals</div>
<div><strong>If you could relive one day with your partner, which day would it be and why:</strong></div>
<div>Saturday</div>
<div>Staying home having conversation about our lovely kids</div>
<div><strong>Why do you and your partner deserve a romantic Valentine’s dinner with Konnect:</strong></div>
<div>Because we are your big customers , we buy your Internet despite have many alternatives and we also started shopping from your stores .</div>`
       },
    {
      id: "5",
       story:`<h4>5. Stephen</h4>
<div><strong>How did you and your partner first meet, and what was your first impression of each other:</strong></div>
<div>Back in 2012 I was a street kid and my wife used to come to uhuru park with his friends ..... I was a vibe guy and funny...so place nilikua nimelala mrembo na marafiki wake walikua wameka next to me ... Na walikua na snacks na soda one of my wife friend akaniuliza Kam Niko njah .. akanipea keki na soda ... So hapo tukanza kuongea story wakinuuliza mbona Niko street na nakaa kijana mazuri ... Tuliongea for long ... So next Sunday my wife and 2 friend come again Kwa mapenzi yangu tukapatana Tena .... And we vibe ... My wife akaniambia ataongea na uncle yake anipe KAZI ... Na uko Kuna place ya kulala so nisikue na stress... I remember adi my wife ndo alininunulia my first nguo za kuchange...so nikaenda job nikafanya job my wife used to come Kwa job every weekend tunashida na yeye ... Nikafanya job nikapata dooh nikarent my own house... As a man nikarusha mistari Kwa mrembo and we start dating.. after 3 years we get married and now we are one happy family with 2 kids - boy and a girl</div>
<div><strong>What's the most romantic or thoughtful thing your partner has ever done for you:</strong></div>
<div>To love and support me ..no matter nowing my background ....alinishow in life you can make it if you believe in yourself</div>
<div><strong>Whats the funniest or most embarrassing moment you’ve experienced as a couple:</strong></div>
<div>Tukiwa tumeenda place mabeshet wa wife wingine apo walikua wanasema ..huyu ndo ule boy Esther alitoanga Kwa street..... Nilifeel but my wife told me people wil take coz that what people do ... Don't let it get in you ... I believe in you</div>
<div><strong>If you could describe your relationship in one movie title, what would it be and why:</strong></div>
<div>True love wait....</div>
<div>It is a showing what the meaning of love</div>
<div><strong>Whats one lesson you’ve learned from your partner that has changed your life:</strong></div>
<div>Don't love book by its cover ....</div>
<div>And love each other</div>
<div><strong>How do you keep the spark alive in your relationship after all these years:</strong></div>
<div>Communication, caring, forgiving, understanding each other, appreciating each other and loving each other</div>
<div><strong>What's one dream or goal you and your partner are working towards together:</strong></div>
<div>A wise man once told me ( the family you create is more important than the family you came from ) our dreams is making sure our kids have better life than sisi and being one happy family</div>
<div><strong>If you could relive one day with your partner, which day would it be and why:</strong></div>
<div>The first day my wife visited me place nilikua nafanya job ... It was most beautiful day of my life,....kuona mtu ananicare</div>
<div><strong>Why do you and your partner deserve a romantic Valentine’s dinner with Konnect:</strong></div>
<div>Since you get married .. sijai peleka bibi yangu on a dinner date this wil be one lifetime opportunity l wil be greatful... Can't wait aki to have that dinner</div>`
        },
        {
        id:"6",
         story:`<h4>6. Joy</h4>
<div><strong>How did you and your partner first meet, and what was your first impression of each other:</strong></div>
<div>We met at an online platform the vibe between us clicked immediately after chatting online and after few days we decided to plan a date and mert</div>
<div><strong>What's the most romantic or thoughtful thing your partner has ever done for you:</strong></div>
<div>Buying me a teddy bear with my name on it</div>
<div><strong>Whats the funniest or most embarrassing moment you’ve experienced as a couple:</strong></div>
<div>We were in town taking photos with him then a random man passed by and sai 'mko busy kupendana badala mwende mkatafute pesa na vile Ruto anatutesa</div>
<div>'😂😂i mean ruto has nothing on us... that was so embarrassing became he really shouted and funny</div>
<div><strong>If you could describe your relationship in one movie title, what would it be and why:</strong></div>
<div>After we met</div>
<div><strong>Whats one lesson you’ve learned from your partner that has changed your life:</strong></div>
<div>Patience is key</div>
<div><strong>How do you keep the spark alive in your relationship after all these years:</strong></div>
<div>Being understanding and patient with each other</div>
<div><strong>What's one dream or goal you and your partner are working towards together:</strong></div>
<div>Buying a house together</div>
<div><strong>If you could relive one day with your partner, which day would it be and why:</strong></div>
<div>When we met</div>
<div>That was the best day of my life after seeing him walk to hug me</div>
<div>Meeting him was the cutest thing</div>
<div><strong>Why do you and your partner deserve a romantic Valentine’s dinner with Konnect:</strong></div>
<div>It will be a great opportunity for us to express our love for each other and continue strengthening our bond</div>
`

        },
        {
          id: "7",
       
         story:`<h4>7. Tyson</h4>
<div><strong>How did you and your partner first meet, and what was your first impression of each other:</strong></div>
<div>We met at Primary school just after I had transferred to their school. She was the beautiful girl i had ever seen .</div>
<div><strong>What's the most romantic or thoughtful thing your partner has ever done for you:</strong></div>
<div>She paid my rent while at campus and also taught me how to save money .</div>
<div><strong>Whats the funniest or most embarrassing moment you’ve experienced as a couple:</strong></div>
<div>She was shy to kiss me during our wedding day. She was shaking</div>
<div><strong>If you could describe your relationship in one movie title, what would it be and why:</strong></div>
<div>Beloveds. Having seen her at primary school and now she being my wife has been a journey of many obstacles, but we've overcame together.</div>
<div><strong>Whats one lesson you’ve learned from your partner that has changed your life:</strong></div>
<div>Patience, trust and always talking to each other is key to a better life together.</div>
<div><strong>How do you keep the spark alive in your relationship after all these years:</strong></div>
<div>Not hiding things from each other. Frequent communication with each other</div>
<div><strong>What's one dream or goal you and your partner are working towards together:</strong></div>
<div>Having a piece of land and building our empire</div>
<div><strong>If you could relive one day with your partner, which day would it be and why:</strong></div>
<div>Saturday, The day we got married.</div>
<div><strong>Why do you and your partner deserve a romantic Valentine’s dinner with Konnect:</strong></div>
<div>When we first saw each other we never thought it will blossom to this, but through downs and working from them we're a couple. Konnect also from inception had challenges but you've never faded from rectifications leading to satisfaction with customers .</div>`

        },
        {
          id: "8",
          story:`<h4>8. Patrick</h4>
<div><strong>How did you and your partner first meet, and what was your first impression of each other:</strong></div>
<div>I met this amazing lady in church.I was then the youth chair of our local church.She came visiting and I was the one to receive her and lead her to the group that she belonged and that was how the story of love began .She innocently have me her contact for follow up .Deep down in me my heart was clicking because I was then healing from a very serious heartbreak.</div>
<div><strong>What's the most romantic or thoughtful thing your partner has ever done for you:</strong></div>
<div>The most romantic and thought full moment was when I first invited her home .It was to be a very casual visit.Immediately I welcomed her in to the house,she loudly exclaimed how my room was well furnished and tinted .It was a simple single room but how she responded on the first day was a communication that she was slowly getting into my circle</div>
<div><strong>Whats the funniest or most embarrassing moment you’ve experienced as a couple:</strong></div>
<div>The funniest moment came on the wedding day .She is taller than I and when this time for " kiss the bride " came ,I literally struggled to reach lips.</div>
<div><strong>If you could describe your relationship in one movie title, what would it be and why:</strong></div>
<div>Love made in heaven - We have literally lived happily.We have not had any major issues .I believe this one was made in heaven</div>
<div><strong>Whats one lesson you’ve learned from your partner that has changed your life:</strong></div>
<div>One lesson that I have learnt is communication is key in relationships.We communicate as clearly as possible with precision and understanding .</div>
<div><strong>How do you keep the spark alive in your relationship after all these years:</strong></div>
<div>It has been 10 years now .The spark is kept by always treating her as if we met yesterday.The "babe" has never left our lips .I don't remember when I called her by name and she too called me by name .</div>
<div><strong>What's one dream or goal you and your partner are working towards together:</strong></div>
<div>We are dreaming of establishing a very stable company together.So far we have registered businesses separately but we are looking forward to merging them so that we come up with an empire of business under our name</div>
<div><strong>If you could relive one day with your partner, which day would it be and why:</strong></div>
<div>If we were to relive one day with my partner,then it would be when she said "yes" to my proposal.It was so emotional because it had literally given up on ever finding a life partner.</div>
<div><strong>Why do you and your partner deserve a romantic Valentine’s dinner with Konnect:</strong></div>
<div>I believe we deserve a Valentine's dinner with connect to relive some of the moments we have heard .This is something we have been planning to do but kind of there has been challenges.</div>`

        }
  ];

  return (
    <div
      style={{
        width: "100%",
        minHeight: "60vh",
        maxWidth: 800,
        backgroundColor: (!success && !hasVoted) ? "#e5e5e5" : "white",
        padding: 10,
        borderRadius: 10,
      }}
    >
       {hasVoted ? (
      <div style={{ padding:"20px", backgroundColor: "#d4edda", borderRadius: "5px" }}>
        <h4 style={{ color: "#155724" }}>Thank you for voting!</h4>
        <div>Your vote has been recorded successfully.</div>
      </div>
    ) : <>
      {!success ? (
        <form onSubmit={handleSubmit}>
          <h4>Vote for your ideal Valentine`s date</h4>

      
          {/* <div className="form-group">
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your full name"
            />
          </div>

        
          <div className="form-group">
            <label htmlFor="phone" className="required">
              Phone Number
            </label>
            <input
              required
              type="tel"
              maxLength={13}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              id="phone"
              placeholder="Enter your phone number"
            />
          </div>

      
          <div className="form-group">
            <label htmlFor="region" className="required">
              Region
            </label>
            <select
              required
              className="form-control"
              id="region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            >
              <option value="">-- --</option>
              {[
                ["Githurai_44/45", "Githurai 44/45"],
                ["Roysambu/Mirema", "Roysambu/Mirema"],
                ["Zimmerman", "Zimmerman"],
                ["Kahawa_West", "Kahawa West"],
                ["Kahawa_Wendani", "Kahawa Wendani"],
                ["Kasarani", "Kasarani"],
                ["Hunters/Mwiki/Sunton", "Hunters/Mwiki/Sunton"],
                ["Lucky_Summer/Baba_Dogo/Ngumba", "Lucky Summer/Baba Dogo/Ngumba"],
                ["kariobangi", "Kariobangi"],
                ["Mowlem_Umoja_3", "Mowlem/Umoja 3"],
                ["Umoja", "Umoja"],
                ["Pipeline", "Pipeline"],
                ["Kibera", "Kibera"],
              ].map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
              <option value="Other">Other</option>
            </select>
          </div> */}

          {/* {region === "Other" && (
            <div className="form-group">
              <label htmlFor="regionOthers">Specify region if `Other` is selected</label>
              <input
                value={regionOthers}
                onChange={(e) => setRegionOthers(e.target.value)}
                type="text"
                className="form-control"
                id="regionOthers"
                placeholder="Enter your region"
              />
            </div>
          )} */}

          {/* Quiz 1 - Radio button with thumbnails and stories */}
          <div className="form-group">
            {/* <label className="required">Vote for your ideal Valentine`s date:</label> */}
            <div>
              {quizOptions.map((option) => (
                <div
                  key={option.id}
                  className="form-check"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                    border: "1px solid #ddd",
                    padding: "20px",
                    borderRadius: "5px",
                    backgroundColor: open_quiz_1 === option.id ? "#cfd8dc" : "transparent",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id={`quiz-option-${option.id}`}
                    name="open_quiz_1"
                    value={option.id}
                    checked={open_quiz_1 === option.id}
                    onChange={(e) => setOpen_quiz_1(e.target.value)}
                    required
                    style={{ marginTop: "5px" }}
                  />
                  <div style={{ marginLeft: "10px", display: "flex", flexDirection: "row",flexWrap:"wrap" }}>
              
                  <StoryWithReadMore story={option.story} />
                    <div  style={{alignSelf:"start",display:"flex",flexDirection:'row',justifyContent:"flex-end",alignItems:"start"}}>   <button
              type="button"
              className="btn btn-outline-primary"
              style={{
                backgroundColor:open_quiz_1 === option.id ? "#007bff" : "transparent",
                color: open_quiz_1 === option.id ?  "white" : "#007bff",
                borderColor: "#007bff",
              }}
              onClick={() => {
                setOpen_quiz_1(option.id)
              }}
            >
              Vote
            </button>
                      </div>
                 
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Vote button */}
          {/* <div style={{ marginBottom: "20px" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{
                backgroundColor: open_quiz_1 ? "#007bff" : "transparent",
                color: open_quiz_1 ? "white" : "#007bff",
                borderColor: "#007bff",
              }}
              onClick={() => {
                if (open_quiz_1) {
                  alert(`You voted for: ${quizOptions.find((option) => option.id === open_quiz_1)?.text}`);
                } else {
                  alert("Please select an option before voting.");
                }
              }}
            >
              Vote
            </button>
          </div> */}

          {/* Submit button */}
          <div style={{ float: "right" }}>
            <button type="submit" className="btn btn-warning" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      ) : (
        <div>
          <div className="alert alert-success" role="alert">
          Thank you for voting for your favorite couple! Your support makes this Valentine`s celebration even more special. Stay tuned for the results! ❤️✨
          </div>
          <div style={{ width: "100%", maxWidth: 1200, float: "center", display: "flex" }}>
            <a href="../../">
              <button className="btn btn-outline-primary" style={{ fontSize: 14 }}>
                Return to Homepage
              </button>
            </a>
          </div>
        </div>
      )}</>}

      {/* Custom Modal */}
      <CustomModal isOpen={!!modalImage} onClose={() => setModalImage("")} imageUrl={modalImage} />
    </div>
  );
}

const CustomModal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          position: "relative",
          maxWidth: 500,
          maxHeight: "60vh",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "none",
            border: "none",
            fontSize: "40px",
            cursor: "pointer",
            zIndex: 100,
            backgroundClip: "white",
            color: "black",
          }}
        >
          &times;
        </button>
        <div style={{ position: "relative", width: "100%" }}>
          <img
            src={imageUrl || "/placeholder.svg"}
            alt="Enlarged view"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
    </div>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
};


const StoryWithReadMore = ({ story }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Truncate the story if not expanded
  const truncatedStory =
    !isExpanded && story.length > 350 ? story.substring(0, 350) + "..." : story;

  return (
    <div style={{ fontSize: "0.9em", flex: 1, minWidth: 300, marginTop: "5px", padding: 10 }}>
      {/* Render the truncated or full story */}
      {ReactHtmlParser(truncatedStory)}

      {/* Show the "Read More" button if the story is truncated */}
      {!isExpanded && story.length > 150 && (
        <button
          type="button"
          className="btn btn-link"
          style={{
            border: "none",
            background: "none",
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "0.9em",
          }}
          onClick={() => setIsExpanded(true)}
        >
          Read More
        </button>
      )}

      {/* Show the "Read Less" button if the story is fully expanded */}
      {isExpanded && (
        <button
          type="button"
          className="btn btn-link"
          style={{
            border: "none",
            background: "none",
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "0.9em",
          }}
          onClick={() => setIsExpanded(false)}
        >
          Read Less
        </button>
      )}
    </div>
  );
};
StoryWithReadMore.propTypes = {
  story: PropTypes.string.isRequired, // Ensure 'story' is a string and required
};