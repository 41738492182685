/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import SwiperCore, { Autoplay, Pagination, EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { fetchOneArticles } from '../Api';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../App.css";

SwiperCore.use([EffectCreative, Pagination,Autoplay]);


const App = (props) => {
 
    const [data, setData] = useState([]);

    useEffect(() => {
     
          const getOneArticle= async()=>{
           try {
          
           fetchOneArticles(props.id).then((data)=>{
            setData([{image:data.main_image},...data.sections]);
            // console.log(data);
       
          
           
  
            })
  
          } catch (error) {
         
            console.log(error);
          }
        }
        getOneArticle()
      
     
    }, []);
   
  return (
    <div style={{width:'100%',maxWidth:800,minWidth:340,display:'flex',flexDirection:'column',justifyContent:'center'}}>
  {data.length>0 &&    <Swiper
    //    spaceBetween={30}
        effect={"creative"}
        grabCursor={true}
        speed={2500}
        loop= {true}  
        // centeredSlides={true}
        autoplay={{
            delay: 8000,
            // disableOnInteraction: false,
          }}
        // slidesPerView={"auto"}
        creativeEffect={{
            prev: {
              shadow: true,
              translate: ['-20%', 0, -1],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
        pagination={true}
        className="mySwiper3"
        // onSlideChange={(swiperCore) => {
            // modules={[EffectCreative]}  
        //   const {
       
        //     realIndex,
        //   } = swiperCore;
        //   props.setBtn_url(props.data?.sections[realIndex]?.content_object.redirect_url)
        //   props.setBtn_text(props.data?.sections[realIndex]?.content_object.button_text)
        //   props.getImage('https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +props.data?.sections[realIndex]?.content_object.main_image);
          // console.log({ activeIndex, snapIndex, previousIndex, snapIndex });
    //   }}
    >

{data.map((img, i) => {
        return (
          <SwiperSlide key={i} style={{position:'relative'}}> 
          {/* <div style={{height:'100%',width:'100%',background:'red'}}> */}
        
   <div style={{display:'flex',justifyContent:'flex-end',width:'100%'}}>
                <div style={{backgroundColor:'white',width:'100%'}}> 
                <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +img.image} alt=""  style={{ height:"46vh",maxHeight:420,objectFit:'cover'}}/>

               </div>

              </div>
          {/* <div style={{width:'100%',height:'100%',position:'absolute',left:0,top:0}}>

</div> */}
          
          </SwiperSlide>
        );
      })}
    </Swiper>}
  </div>
);
};

export default App;