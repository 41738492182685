import '../App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useState,useEffect } from 'react';
import { getJobs } from '../Api';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'; 
const App = () => {
    const [jobs,setJobs]=useState([])
    useEffect(() => {
        const fetchJobs = async () => {
          try {
            // setLoading(true)
 getJobs().then(data=>{
//   console.log(data);
setJobs(data.results)
            });
            // set(data.results);
          
              // setLoading(false)
          } catch (error) {
            // setLoading(false)
            console.log(error);
          }}
          fetchJobs()
    
    
      }, []);
    return (
 <div style={{width:'100%',display:'flex',justifyContent:'center',minHeight:'60vh'}}>

<div style={{width:'100%',maxWidth:800,padding:10,margin:10,background:'white',minHeight:500}}>
    <h3>Jobs Available</h3>
    {jobs.map((job,i)=>{
        return(
     <div key={i} className='card' style={{margin:10,padding:10}}>

<h5 style={{color:'orange',userSelect:'none'}}>{job.title}</h5>
<h4 style={{color:'black'}}>Job Description</h4>
<div style={{fontSize:12}}>
    
    {ReactHtmlParser(job.job_description) }
    </div>

    <p> <b style={{float:'right',fontSize:13}}>Deadline: { new Date(job?.deadline?.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</b></p>
    <Link to={'/job/apply/'+job.slug}>APPLY NOW</Link>
    </div>       
        )
    })}
{jobs.length==0 && <div style={{color:'gray',fontSize:18,width:'100%',textAlign:'center'}}>No Jobs Available</div>}
    
</div>
    </div>

  );
};

export default App;