/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import React, { useState } from 'react';
import {postContactUs} from '../Api'
const ContactUsModal = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [region, setRegion] = useState('');
  const [subject, setSubject] = useState('');
  const [loading, setLoading] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
  
  
    if(loading==false){
  setLoading(true)
     const obj={
      name,
      phone,
      email,
      region,
      subject,
      message
    }
   postContactUs(obj).then(()=>{
    setEmail('')
    setMessage('')
    setName('')
    setPhone('')
    setRegion('')
    setMessage('')
    setSubject('')
    setLoading(false)
       handleClose();
       handleButtonClick()
   })
 
    }

  }
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const [showDiv, setShowDiv] = useState(false);

  const handleButtonClick = () => {

    setShowDiv(true);
        setTimeout(()=>{
      setShowDiv(false);
    },3000)
    
  }
  return (
    <>
 
<div style={{position:'fixed',width:'100%',left:0,top:100,display:'flex',flexDirection:'row',justifyContent:'center',zIndex:1000}}>



{showDiv && (
    <div className={`alert alert-success slide-in ${showDiv ? 'show' : ''}`} role="alert">
    Message sent successfully
   </div>
      )}
</div>
{props.type=='comment'?      <button className="btn btn-outline-danger btn-sm ml-1 shadow-none" style={{fontWeight:"bold"}} onClick={handleShow} >Report an Issue</button>:      <button   onClick={handleShow} className="btn btn-warning btn-outline" style={{margin:0,marginBottom:0,height:30,paddingTop:0,paddingLeft:10,paddingRight:10}}  >Chat Us</button>
 }

      <div className={`modal fade scrollable-container ${show ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' ,textAlign:'left',overflow:'scroll'}}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Contact Us</h5>  
              <button type="button" className="close" onClick={handleClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name" className="required">Name
                  
                  </label>
                  <input required value={name} onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control" id="name" placeholder="Enter your full name"  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="required">Phone Number</label>
                  <input required type="tel" maxLength={13} value={phone}  onChange={(e)=>{setPhone(e.target.value)}}  className="form-control" id="phone" placeholder="Enter your phone number"  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="email" >Email address</label>
                  <input type="email" value={email}  onChange={(e)=>{setEmail(e.target.value)}}  className="form-control" id="email" placeholder="Enter your email"  />
                </div> */}
                <div className="form-group">
  <label htmlFor="selectOption" className="required">Subject</label>
  <select required className="form-control" id="selectOption"  value={subject} onChange={handleSubjectChange}>
  <option value=''>-- --</option>
  <option value={'Payment Follow up'}>Payment Follow up</option>
    <option value={'Inquiry on products/offers'}>Inquiry on products/offers</option>
    <option value={'Report an issue'}>Report an issue</option>
    <option value={'Request to install home Internet'}>Request to install home Internet</option>
    <option value={'Konnect Hub events/programs'}> Konnect Hub events/programs</option>
    <option value={'Suggestion/comment/question'}>Suggestion/comment/question</option>

  </select>
</div>
<div className="form-group">
  <label htmlFor="selectOption" className="required">Region</label>
  <select required className="form-control" id="selectOption" value={region} onChange={handleRegionChange}>
  <option value=''>-- --</option>
  <option value="G44">Githurai 44</option> 
    <option value="G45">Githurai 45</option>
    <option value="Zimmerman">Zimmerman</option>
    <option value="Roysambu">Roysambu/Mirema</option>  
    <option value="Kahawa_West">Kahawa West</option>  
    <option value="Hunters">Hunters</option>  
    <option value="Mwiki">Mwiki</option>  
    <option value="Sunton">Sunton</option> 
    <option value="Kasarani">Kasarani</option>  
    <option value="Lucky_Summer">Lucky Summer</option>  
    <option value="kahawa_wendani">Kahawa Wendani</option>  
    <option value='Umoja_1'>Umoja 1</option>
<option value='Umoja_2'>Umoja 2</option>
<option value='Umoja_3'>Umoja 3</option>
<option value='Pipeline'>Pipeline</option>
<option value='Kayole'>Kayole</option>
<option value='Rongai'>Rongai</option>
<option value='Kibera'>Kibera</option>
<option value='Saika'>Saika</option>
<option value='Mwihoko'>Mwihoko</option>
    <option value="Other">Other</option>
  </select>
</div>
                <div className="form-group">
                  <label htmlFor="message" className="required">Message</label>
                  <textarea required value={message}  onChange={(e)=>{setMessage(e.target.value)}} className="form-control" id="message" rows="3" placeholder="Enter your message" ></textarea>
                </div>
                <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <small style={{color:'orange',fontWeight:'bold',float:'right',marginLeft:20}}><i style={{color:'black',fontWeight:'normal'}}>You can also call us<br/> on</i> 0701888666</small>
             {!loading ? <button type="submit" className="btn btn-warning">Send</button>: <button  className="btn btn-warning">Sending ...</button>}
             
             
             </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {show && <div className="modal-backdrop fade show"></div>}
    </>
  );
}

export default ContactUsModal;
