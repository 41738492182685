/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import '../App.css';
import React, { useState, useEffect } from 'react';
// import ReactHtmlParser from 'react-html-parser'; 
import { fetchArticles} from '../Api';
import { Link } from 'react-router-dom';
const Community = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
   useEffect(() => {
      const getArticles = async () => {
        try {
          setLoading(true)
          const data = await fetchArticles();
          setData(data.results);
    
            setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error);
        }}
        getArticles()
  }, []);
 
  
  useEffect(() => {
    window.scrollTo(0, 0);
  
   },[])


  return (
    <div style={{padding:5,display:'flex',flexDirection:'column',alignItems:'center'}}>
      {/* <h4>Community</h4> */}
      {/* {data.filter((item,i)=>i==0).map((item,idx)=>{
  return(<div key={idx} className="row_class"  style={{width:'100%',justifyContent:'center'}}>
    <div style={{flex:3,minWidth:340}}>   
     <h5 className="card-title " style={{color:'orange'}}>{item.title}</h5>
  
  <small style={{color:'gray'}}>  Date posted: {' '}  {new Date(item?.date_created.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</small>
  <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.main_image} className="card-img-top" alt="..."/>
  <small style={{color:'gray'}}> Author: {item.user.name}</small>
  </div>
  <div className="card-body" style={{flex:2,minWidth:200}}>

 
    <div  style={{fontSize:14,paddingTop:40,lineHeight:'25px'}}>
       {ReactHtmlParser(item.main_section_msg)[0] }
   
    
    </div>
    <Link to={'/community/'+item.slug} className="btn btn-outline-primary btn-outline">Read More</Link>
  </div>
</div>)
})} */}
 {/* {data.length>0 && <div style={{borderBottom:'2px solid orange'}}>
      <div style={{padding:5,lineHeight:'20px',color:'white',marginTop:10,background:'orange',width:130}}>Other stories</div>
      
    </div>
    } */}

    <br/>
    <br/>
    <div style={{width:'100%',borderLeft:'8px solid orange',background:'#f8f8f8',textAlign:'left',maxWidth:1366,padding:10}}>
    <Link to='/community' style={{display:'block'}}>
  <h5 style={{margin:0,padding:0,textDecoration:'none',color:'black'}}>Community / Articles</h5>
  </Link>
    </div>
  
     {loading &&
    <div style={{width:'100%',paddingTop:50,display:'flex',flexDirection:'row',justifyContent:'center'}}>
   <img alt='' src={'images/loader.gif'}  width={80} />
  </div>
    }
  

      <div className='row_class' style={{maxWidth:1366,justifyContent:'space-around'}}>
{data?.filter((item,i)=> i<3).map((item,idx)=>{
  return(<div key={idx} className=" card_item"  style={{borderRadius:8,overflow:'hidden',minWidth:340,position:'relative',boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23)'}}>
  {data.length>0 && item?.main_image==null && <div className="activity"></div>}
   <Link to={'/community/'+item.slug}> 
   
   
   <div className="load-wraper"  style={{minHeight:230,minWidth:300,
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:'cover',
        backgroundImage:data[0]?.main_image!=null ? `url(${'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item?.main_image})`: 'transparent'}}>

        </div>
   {/* <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file='  +item.main_image} className="card-img-top" alt="..." style={{minHeight:200}}/> */}
   </Link>
  

  <div style={{width:'100%',padding:5,display:'flex',flexDirection:'row',color:'gray',fontSize:11,alignItems:'flex-start',justifyContent:'space-between'}}>

    <span>Article by {item?.author ?? item?.user?.name}</span>
    <span> Date posted: {' '}  {new Date(item?.date_created.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</span>
  </div>
  <div style={{width:'100%',padding:10,paddingBottom:5,display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
<div style={{display:'flex'}}>
{/* ,left:0,bottom:0,position:'absolute' ,background:'rgba(0,0,0,0.7)'style={{borderColor:'orange',color:'orange'}}*/}

  <div style={{flex:1,minHeight:34}}>  <Link  to={'/community/'+item.slug} style={{textDecoration:'none',color:'white'}}>
   
   <h5 className="card-title card_title com_title"  style={{color:'black',fontSize:14,fontWeight:'nromal',lineHeight:'16px',margin:0}}> {item.title}</h5></Link>
  </div></div>
  <div style={{width:10}}></div>

</div>

  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
        <Link to={'/community/'+item.slug}  style={{color:'orange',fontWeight:'bold',border:0,fontSize:12,margin:5,marginRight:20,marginBottom:10}} >READ MORE</Link>
  
    </div>
  {/* <div className="card-body">
   <Link  to={"community/"+item.id} style={{textDecoration:'none'}}>
   
    <h5 className="card-title card_title">{item.title}</h5></Link>

    <div className='truncate-text'>
       {ReactHtmlParser(item.main_section_msg)[0] }
   
    
    </div>
    <small style={{color:'gray'}}>  Date posted: {' '}  {item.date_created.substring(0,10)}</small>
    <br/>
    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
        <Link to={"community/"+item.slug} style={{alignSelf:'right'}} className="btn btn-outline-primary btn-outline">Read More</Link>
  
    </div>
  </div> */}
</div>)
})}
<div style={{minWidth:340}}></div>
<div style={{minWidth:340}}></div>
</div>

    </div>
  );
};

export default Community;