

/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import '../App.css';
import React,{useEffect, useState} from 'react'
import { createSurvey,getSurveys } from '../Api';
import { Link } from 'react-router-dom';
export  const KonnectSurvey=()=> {

    const [error,setError] = useState("");
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    
    const [showModal, setShowModal] = useState(false);
    const [list,setList]=useState([])
    const [loading,setLoading] = useState(false);
    const openModal = () => {
      setShowModal(true);
    };
    
    const closeModal = () => {
      setShowModal(false);
    };
      const fetchSurvey = async () => {
        try {
          const data = await getSurveys();
          // console.log(data)
          setList(data)
        } catch (error) {
          // Handle error
        }}
    useEffect(()=>{
    
        fetchSurvey()
    },[])
    
    
    
    // const getSurveys=async ()=>{
    //   setLoading(true)
    // const g=query(collection(db, "survey_schema") );
    
    //   const data =await  getDocs(g);
    //   var result=data.docs.map((doc)=>({...doc.data(),id:doc.id}));
    //    setList(result)
    //   setLoading(false)
    // }
    
    function slugGenerator(inputString) {

      const transformedString = inputString.replace(/\s+/g, '-');
      
      const randomNumber = Math.floor(Math.random() * 90000) + 10000;
      
  
      const finalString = transformedString + "-" +randomNumber;
      
      return finalString;
    }
    const createNewDocument = async () => {
    
      if(title.trim()!='' || description.trim()!=''){
        
      setError('')
      closeModal()
      // const collectionRef = collection(db, 'survey_schema');
    var data={
      "title":title,
      "description":description,
      "slug":slugGenerator(title),
      "pages":[]
    }
      try {
        setLoading(true)
        // const newDocRef = await addDoc(collectionRef, data);
        // console.log('New document created with ID:', newDocRef.id);
        // setDescription('')
        // setTitle('')
        createSurvey(data).then(()=>{
//  console.log(response)
 setLoading(false)
 fetchSurvey()
        })
        // getSurveys()
        // setLoading(false)
       
        
        // Perform any necessary actions after the document is created
      } catch (error) {
        setLoading(false)
        console.error('Error creating new document:', error);
        // Handle the error case
      }}else{
        setError('Please fill in the title and description')
      }
    };
    
    
      return (
        <div>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <Link className="navbar-brand" to="/survey/admin"> Konnect (Survey Creator) </Link>
      
     
    </nav>
    
    <div style={{width:'100vw',display:'flex',flexDirection:'row',justifyContent:'center'}}>
    
            <div style={{width:'100vw',maxWidth:1000,display:'flex',flexWrap:'wrap',flexDirection:'column',justifyContent:'space-between'}}>
            <div className='surveycard2' style={{width:'100%',paddingTop:20,}} onClick={openModal}>
              
              <button className="btn btn-warning ml-2" >+Create New Survey</button></div>
            
                {list.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)).map((item,i)=><div  key={i}  className='surveycard'>
                  <div style={{margin:10}}>
                  <div className="card-body">
    <h5 className="card-title"> {item.title}  </h5>
    {/* <h6 className=" mb-2 ">Card subtitle</h6> */}
    <p className="card-text card-subtitle text-muted"> {item.description}</p>
    <Link to={'/survey/admin/edit/'+item.slug}  className="card-link">Edit</Link>
    <Link to={'/survey/admin/analytics/'+item.id+'/'+item.slug}   className="card-link">Analytics</Link>
    <Link to={'/survey/view/'+item.slug}   className="card-link">View</Link>
  </div>
                            
                  
                   {/* <br/>
                <div><small style={{color:'gray'}}>{item.description}</small></div> */}
                
                
                </div>
    
                </div>)}
            </div></div>
    
            {loading &&  <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',color:'white',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)'}}>
    <strong>Loading...</strong>
    
            </div>}
    
        
            {showModal &&  <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)'}}>
            <div   onClick={closeModal} style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',position:'fixed',top:0,left:0}}>
     </div>
      <div className="modal-dialog" role="document">
                 <div className="modal-content" style={{minWidth:'400px'}}>
                   <div className="modal-header">
                     <h5 className="modal-title">Create new survey</h5>
                     {/* <button
                       type="button"
                       className="btn-close"
                       onClick={closeModal}
                       aria-label="Close"
                     >Close</button> */}
                   </div>
                   <div className="modal-body">
                     <div className="mb-3">
                       <label htmlFor="title" className="form-label">Title</label>
                       <input
                         type="text"
                         className="form-control"
                         id="title"
                         value={title}
                         onChange={(e) => setTitle(e.target.value)}
                       />
                     </div>
                     <div className="mb-3">
                       <label htmlFor="description" className="form-label">Description</label>
                       <textarea
                         className="form-control"
                         id="description"
                         rows="3"
                         value={description}
                         onChange={(e) => setDescription(e.target.value)}
                       ></textarea>
                     </div>
                     <small style={{color:'red'}}>  {error}</small>
                   </div>
                   
                 
                      <div className="modal-footer">
                      <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={()=>{createNewDocument()}}
                        >
                        Create Survey
                        </button>
                      </div>
                    </div>
                  </div>
            </div>}
        </div>
      
      )
    }
    export default KonnectSurvey;