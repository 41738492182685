/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import '../App.css';
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router-dom';
import { fetchOneArticles_notice, fetchComments } from '../Api';
import CommentSection from './comment';
import { Helmet } from 'react-helmet';
import EventApplication from './eventApplication';
import EventApplicationV2 from './eventApplication-2';
import EventApplicationV3 from './eventApplication-3';

const Community = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [idComment, setIdComment] = useState('');
  const [loading, setLoading] = useState(null);
  const [comment, setComment] = useState([]);
  const [showNoticeBody, setShowNoticeBody] = useState(true); // State to control body visibility
  const [url, setUrl] = useState('');
  const [modal, setModal] = useState(false);

  // Function to hide the body contents
  const handleFormSubmitSuccess = () => {
    setShowNoticeBody(false);
  };

  const getComments = async (i) => {
    try {
      setLoading(true);
      const data = await fetchComments(i);
      setComment(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    const getOneArticle = async () => {
      try {
        fetchOneArticles_notice(id).then((data) => {
          setData(data);
          getComments(data.id);
          setIdComment(data.id);
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getOneArticle();
  }, [id]);

  return (
    <div style={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', minHeight: '60vh' }}>
      <Helmet>
        <title>{'Karibu Konnect | ' + id}</title>
        <meta name="description" content={data?.title} />
      </Helmet>

      {loading && (
        <div style={{ width: '100%', paddingTop: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <img alt="" src={'../images/loader.gif'} height={80} />
        </div>
      )}

      {data?.title != null && (
        <div className="row_class" style={{ width: '100%', justifyContent: 'center', flexDirection: 'column', maxWidth: 800 }}>
          <div style={{ flex: 3, minWidth: 340, position: 'relative' }}>
            <h5 className="card-title" style={{ color: 'orange', fontSize: 24 }}>{data?.title}</h5>
            {data?.category !== "announcement" && (
              <div>
                <small style={{ color: 'gray' }}>
                  {new Date(data.date_created.substring(0, 10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}{' '}
                  {data.date_created.substring(11, 16)}
                  {' by '}
                </small>
                <small style={{ color: '#0D47A1' }}>{data.author ?? data.user.name}</small>
              </div>
            )}

            {/* Conditionally render body contents */}
            {showNoticeBody && (
              <>
                {data.main_image != null && (
                  <img
                    src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' + data.main_image}
                    className="card-img-top"
                    alt="..."
                  />
                )}
                <div
                  onClick={() => {
                    setModal(true);
                    setUrl('https://cdn.sasakonnect.net/content?bucket=landing-page&file=' + data.main_image);
                  }}
                  style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }}
                ></div>
                <small style={{ color: 'gray' }}>{data.main_image_caption}</small>
              </>
            )}
          </div>

          {showNoticeBody && (
            <div className="card-body" style={{ flex: 2, minWidth: 200 }}>
              <div style={{ fontSize: 14, paddingTop: 0, lineHeight: '25px' }}>
                {ReactHtmlParser(data.main_section_msg)}
              </div>
            </div>
          )}
        </div>
      )}

      {showNoticeBody && data?.title != null &&
        data.sections.map((item, idx) => (
          <div key={idx} style={{ width: '100%', justifyContent: 'center', flexDirection: 'column', maxWidth: 800 }}>
            <div style={{ flex: 3, minWidth: 340, position: 'relative' }}>
              {item.image !== '' && (
                <img
                  src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' + item.image}
                  className="card-img-top"
                  alt="..."
                />
              )}
              <div
                onClick={() => {
                  setModal(true);
                  setUrl('https://cdn.sasakonnect.net/content?bucket=landing-page&file=' + item.image);
                }}
                style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }}
              ></div>
              <small style={{ color: 'gray' }}>{data.image_caption}</small>
            </div>
            <div className="card-body" style={{ flex: 2, minWidth: 200 }}>
              <div style={{ fontSize: 16, paddingTop: 0, lineHeight: '25px' }}>{ReactHtmlParser(item.msg)}</div>
            </div>
          </div>
        ))}

      {loading === false &&
        data?.category !== 'promotedevent' &&
        data?.category !== 'promotedeventV2' &&
        data?.category !== 'promotedeventV3' && (
          <CommentSection
            showPhone={data?.phone_in_comment}
            getComments={getComments}
            comments={comment}
            id={idComment}
            title={data?.title}
            likes={data?.likes}
            slug={data?.slug}
          />
        )}

      {data?.category === 'promotedevent' && (
        <EventApplication
          title={data?.slug}
          onFormSubmitSuccess={handleFormSubmitSuccess} // Pass the function to hide the body
        />
      )}
      {data?.category === 'promotedeventV2' && (
        <EventApplicationV2
          title={data?.slug}
          // onFormSubmitSuccess={handleFormSubmitSuccess} // Pass the function to hide the body
        />
      )}
      {data?.category === 'promotedeventV3' && (
        <EventApplicationV3
          title={data?.slug}
          // onFormSubmitSuccess={handleFormSubmitSuccess} // Pass the function to hide the body
        />
      )}

      
      
      {modal && (
        <div
          style={{
            width: '100vw',
            zIndex: 40,
            height: '100vh',
            background: 'rgba(0,0,0,0.7)',
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            onClick={() => {
              setModal(!modal);
            }}
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          ></div>
          <div style={{ width: '95%', maxWidth: 1000, minWidth: 360, zIndex: 51, position: 'relative' }}>
            <img src={url} width="100%" />
            <div style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Community;