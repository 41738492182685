/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect,useState } from 'react';
import TiktokView from './videoView'


import { fetchTiktok} from '../Api';

const VideoGallery = (props) => {
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    const getTiktok = async () => {
      try {
        // setLoading(true)
        const data2 = await fetchTiktok('jikoni');
        setVideos(data2.results);
        // console.log(data2);
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }
    }
    getTiktok()

 
  }, []);

  const videoRefs = useRef([]);
 const [display,setDisplay]=useState(false)
  useEffect(() => {
    const options = {
      threshold: 0.5, // Adjust the threshold value as needed
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    videoRefs.current.forEach((videoElement) => {
      if (videoElement) {
        observer.observe(videoElement);
        videoElement.addEventListener('play', handlePlay);
        videoElement.addEventListener('ended', handleEnded);
      }
    });

    // Clean up the event listener when the component unmounts
    return () => {  
            observer.disconnect();
      videoRefs.current.forEach((videoElement) => {
        if (videoElement) {
          videoElement.removeEventListener('ended', handleEnded);
          videoElement.removeEventListener('play', handlePlay);
        }
      });
    };
 
  }, []);
useEffect(()=>{

props.setShow(!display)
},[display])

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // entry.target.play();
      } else {
        entry.target.pause();
      }
    });
  };

  // Play event listener
  const handlePlay = (event) => {
    // console.log('Video is playing:', event.target);
    const currentVideo = event.target;
    videoRefs.current.forEach((videoElement) => {
      if (videoElement && videoElement !== currentVideo) {
        videoElement.pause();
      }
      
    });
  };
  const handleEnded = (event) => {
    const videoElement = event.target;
  videoElement.currentTime = 0; 
  videoElement.play(); 
  };
    
  return (<> 
   {/* <h3 onClick={()=>{setDisplay(!display)}}>View Videos</h3> */}
    <div className='scrollable-container' style={{
      display: 'flex',
      width: '100%',
      maxWidth: 1200,
      overflow: 'auto',
      
      flexDirection: 'row',
      justifyContent: 'space-between',background:'#f8f8f8',margin:5
    }}>
    
      {!display && videos.map((video, index) => (
        <div  key={index} style={{position:'relative'}}>
   <video
        
          ref={(ref) => (videoRefs.current[index] = ref)}
          controls
          style={{
            display: 'inline-block',
            margin: '0 10px',
            
            width: '300px',
          }}

          src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +video.video_file} type="video/mp4"
        />
        <div onClick={()=>{setDisplay(true);}} style={{position:'absolute',top:0,left:0,width:'100%',height:'100%',background:'rgba(0,0,255,0.0)'}}></div>

        </div>
          
     
      
      ))}
{display && 
<TiktokView setDisplay={setDisplay} videos={videos}/>
}
    </div></>
  );
};

export default VideoGallery;
