/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect,useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
// import useScrollSnap from 'react-use-scroll-snap';
import useScrollSnap from "react-use-scroll-snap";

const VideoGallery = ({videos,setDisplay}) => {
  const videoRefs = useRef([]);
  const [activeSection, setActiveSection] = useState(0);
  const scrollContainerRef = useRef(null);
  useScrollSnap({ ref: scrollContainerRef, duration: 50, delay: 20 })
  // const scrollRef = useRef(null);
  // useScrollSnap({ ref: scrollContainerRef, duration: 100, delay: 50 });

  
  useEffect(() => {
    const options = {
      threshold: 0.5, // Adjust the threshold value as needed
    };
   
    // const scrollUpdate = (e) => {
    //   setCurrentScrollPos(e.target.scrollLeft);
    // };
    // scrollSnap.addEventListener('scroll', scrollUpdate);
    const observer = new IntersectionObserver(handleIntersection, options);

    videoRefs.current.forEach((videoElement)=> {
    
      if (videoElement) {
         
        observer.observe(videoElement);
        videoElement.addEventListener('play', handlePlay);
        videoElement.addEventListener('ended', handleEnded);
       
      }
    });
  
  
    // Clean up the event listener when the component unmounts
    return () => {  
      // scrollSnap.removeEventListener('scroll', scrollUpdate);
            observer.disconnect();
        //  window.removeEventListener('scroll', handleScroll);
      videoRefs.current.forEach((videoElement) => {
        if (videoElement) {
          videoElement.removeEventListener('ended', handleEnded);
          videoElement.removeEventListener('play', handlePlay);
          
        }
      });
    };
 
  }, []);

  useEffect(() => {

 
    const scrollContainer = scrollContainerRef.current;
    const activeSectionElement = scrollContainer.children[activeSection];
    const offsetTop = activeSectionElement.offsetTop;
    const scrollTop = offsetTop - scrollContainer.offsetTop-50;
    scrollContainer.scrollTo({ top: scrollTop, behavior: 'smooth' });

    
  }, [activeSection]);
  const handleIntersection = (entries) => {

    entries.forEach((entry) => {
    
        
        entry.target.pause();
      
    });
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // setActiveSection(i+1)
        entry.target.play();
        
      } else {
        
        entry.target.pause();
      }
    });
  };

  // Play event listener
  const handlePlay = () => {
    // console.log('Video is playing:', event.target);
    // const currentVideo = event.target;
    // videoRefs.current.forEach((videoElement) => {
    //   if (videoElement && videoElement !== currentVideo) {
    //     videoElement.pause();
    //   }
      
    // });
  };
  const handleEnded = (event) => {
    const videoElement = event.target;
  videoElement.currentTime = 0; 
  videoElement.play(); 
  };
  const scrollToNextItem = () => {
    const nextSectionIndex = activeSection + 1;
    if (nextSectionIndex < videos.length) {
      setActiveSection(nextSectionIndex);
    }
  }
  const scrollToPreviousItem = () => {
    const previousSectionIndex = activeSection - 1;
    if (previousSectionIndex >= 0) {
      setActiveSection(previousSectionIndex);
    }
  };
 
  
  return (
    <div className='my-element' style={{
      display: 'flex',
      width: '100vw',

      overflow: 'hidden',
      background: '#f8f8f8',
      flexDirection: 'row',
      justifyContent: 'center'
    }}>
     
     {/* <divstyle={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh',background:'red' }}>dfdfd</div> */}

        
     <button   onClick={() => { setDisplay(false) }} style={{ position:'fixed',top:60,zIndex:100,border:0,width:60,height:60,background:'rgba(255,255,255,0.7)',right:10,borderRadius:30,boxShadow:'0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23)'}} >
        <CloseIcon style={{ color: 'red' }} />
        </button>
        <button style={{ position:'fixed',top:140,zIndex:100,border:0,width:60,height:60,background:'rgba(255,255,255,0.7)',right:10,borderRadius:30,boxShadow:'0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23)'}} onClick={scrollToPreviousItem}>
        <ArrowDropUpIcon/>
        </button>
    
      <div style={{width:'100%',height:'100vh',overflow:'hidden',background:'rgba(255,255,255,1)',display:'flex',flexDirection:'column',paddingTop:80,paddingBottom:800,zIndex:0,alignItems:'center',position:'absolute',top:0,left:0}} 

      ref={scrollContainerRef} 
      >
   
      {videos.map((video, index) => (
        <div style={{zoom:1.15,marginTop:0,display:'flex',flexDirection:'column'}}  key={index} className={`section ${activeSection === index ? 'active' : ''}`}>
         {index==0 &&  <div style={{height:'25vw',maxHeight:100}}></div>}
          <div>
            <video
        
       
          ref={(ref) => (videoRefs.current[index] = ref)}
          controls
          style={{
            display: 'inline-block',
            margin: '10px',
            width: '300px'
          }}

          src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file=' +video.video_file} type="video/mp4"
        /> 
           </div>
      
         <div style={{height:'25vw',maxHeight:150}}></div>
        </div>
  
      
      ))}

      </div> 
      
   <div style={{height:50,width:'100vw',background:'rgba(255,255,250,0.7)',boxShadow: '0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24)',position:'absolute',top:0,left:0,display:'flex',flexDirection:'center',justifyContent:'center'}}>

<img style={{height:40,margin:5}} src={'./images/logo3.png'} />
</div>
 
        <button  style={{ position:'fixed',bottom:30,zIndex:100,border:0,width:60,height:60,background:'rgba(255,255,255,0.7)',right:10,borderRadius:30,boxShadow:'0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23)'}}  onClick={scrollToNextItem}>
        <ArrowDropDownIcon/>
        </button>
      
    </div>
  );
};

export default VideoGallery;
