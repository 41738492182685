import React,{ useState } from "react"
import { postEventApplication_2 } from "../Api"
import { useParams } from "react-router-dom"

export default function EventApplication() {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [region, setRegion] = useState("")
  const [regionOthers, setRegionOthers] = useState("")
  const [open_quiz_1, setOpen_quiz_1] = useState("")
  const [open_quiz_2, setOpen_quiz_2] = useState("")
  const [open_quiz_3, setOpen_quiz_3] = useState([])
  const [message_quiz_2, setMessage_quiz_2] = useState("")
  const [message_quiz_3, setMessage_quiz_3] = useState("")
  const [message_quiz_4, setMessage_quiz_4] = useState("")
  const [message_quiz_5, setMessage_quiz_5] = useState("")
  const [message_quiz_6, setMessage_quiz_6] = useState("")
  const [message_quiz_7, setMessage_quiz_7] = useState("")
  const [message_quiz_8, setMessage_quiz_8] = useState("")
  const [message_quiz_9, setMessage_quiz_9] = useState("")
  const [message_quiz_10, setMessage_quiz_10] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)

  const { id } = useParams()

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    const formData = new FormData()
    formData.append("title", id)
    formData.append("name", name)
    formData.append("phone", phone)
    formData.append("residency", region)
    formData.append("residency_other", regionOthers)
    formData.append("open_quiz_1", open_quiz_1)
    formData.append("open_quiz_2", open_quiz_2)
    formData.append("open_quiz_3", open_quiz_3.join(", "))
    for (let i = 2; i <= 10; i++) {
      formData.append(`message_quiz_${i}`, eval(`message_quiz_${i}`))
    }
    if (selectedFile) {
      formData.append("pic", selectedFile)
    }

    try {
      const result = await postEventApplication_2(formData)
      if (result) {
        setSuccess(true)
        // Reset form
        setName("")
        setPhone("")
        setRegion("")
        setRegionOthers("")
        setOpen_quiz_1("")
        setOpen_quiz_2("")
        setOpen_quiz_3([])
        setMessage_quiz_2("")
        setMessage_quiz_3("")
        setMessage_quiz_4("")
        setMessage_quiz_5("")
        setMessage_quiz_6("")
        setMessage_quiz_7("")
        setMessage_quiz_8("")
        setMessage_quiz_9("")
        setMessage_quiz_10("")
        setSelectedFile(null)
      }
    } catch (error) {
      console.error("Error submitting application:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        width: "100%",
        minHeight: "60vh",
        maxWidth: 800,
        backgroundColor: !success ? "#e5e5e5" : "white",
        padding: 10,
        borderRadius: 10,
      }}
    >
      {!success ? (
        <form onSubmit={handleSubmit}>
          <h4>Konnect Valentine Dinner</h4>

          {/* Name */}
          <div className="form-group">
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your full name"
            />
          </div>

          {/* Phone */}
          <div className="form-group">
            <label htmlFor="phone" className="required">
              Phone Number
            </label>
            <input
              required
              type="tel"
              maxLength={13}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              id="phone"
              placeholder="Enter your phone number"
            />
          </div>

          {/* Region */}
          <div className="form-group">
            <label htmlFor="region" className="required">
              Region
            </label>
            <select
              required
              className="form-control"
              id="region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            >
              <option value="">-- --</option>
              {[
                ["Githurai_44/45", "Githurai 44/45"],
                ["Roysambu/Mirema", "Roysambu/Mirema"],
                ["Zimmerman", "Zimmerman"],
                ["Kahawa_West", "Kahawa West"],
                ["Kahawa_Wendani", "Kahawa Wendani"],
                ["Kasarani", "Kasarani"],
                ["Hunters/Mwiki/Sunton", "Hunters/Mwiki/Sunton"],
                ["Lucky_Summer/Baba_Dogo/Ngumba", "Lucky Summer/Baba Dogo/Ngumba"],
                ["kariobangi", "Kariobangi"],
                ["Mowlem_Umoja_3", "Mowlem/Umoja 3"],
                ["Umoja", "Umoja"],
                ["Pipeline", "Pipeline"],
                ["Kibera", "Kibera"],
              ].map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
              <option value="Other">Other</option>
            </select>
          </div>

          {region === "Other" && (
            <div className="form-group">
              <label htmlFor="regionOthers">Specify region if `Other` is selected</label>
              <input
                value={regionOthers}
                onChange={(e) => setRegionOthers(e.target.value)}
                type="text"
                className="form-control"
                id="regionOthers"
                placeholder="Enter your region"
              />
            </div>
          )}

          {/* Partner Name */}
          <div className="form-group">
            <label htmlFor="open_quiz_1" className="required">
              Partner Name
            </label>
            <input
              type="text"
              className="form-control"
              id="open_quiz_1"
              value={open_quiz_1}
              onChange={(e) => setOpen_quiz_1(e.target.value)}
              required
            />
          </div>

          {/* Partner Phone Number */}
          <div className="form-group">
            <label htmlFor="open_quiz_2" className="required">
              Partner Phone Number
            </label>
            <input
              type="tel"
              className="form-control"
              id="open_quiz_2"
              value={open_quiz_2}
              onChange={(e) => setOpen_quiz_2(e.target.value)}
              required
            />
          </div>

          {/* Which Konnect Services are you using */}
          <div className="form-group">
            <label className="required">Which Konnect Services are you using?</label>
            <div>
              {["Konnect Water", "Konnect Wallet", "Konnect Internet", "Konnect Foodplus"].map((service) => (
                <div key={service} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`service-${service.toLowerCase().replace(" ", "-")}`}
                    value={service}
                    checked={open_quiz_3.includes(service)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOpen_quiz_3([...open_quiz_3, service])
                      } else {
                        setOpen_quiz_3(open_quiz_3.filter((s) => s !== service))
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor={`service-${service.toLowerCase().replace(" ", "-")}`}>
                    {service}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Valentine`s Questions using message_quiz fields */}
          <div className="form-group">
            <label htmlFor="message_quiz_2">
              How did you and your partner first meet, and what was your first impression?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_2"
              value={message_quiz_2}
              onChange={(e) => setMessage_quiz_2(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message_quiz_3">
              What`s the most romantic or thoughtful thing your partner has ever done for you?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_3"
              value={message_quiz_3}
              onChange={(e) => setMessage_quiz_3(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message_quiz_4">
              What`s the funniest or most embarrassing moment you`ve experienced as a couple?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_4"
              value={message_quiz_4}
              onChange={(e) => setMessage_quiz_4(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message_quiz_5">
              If you could describe your relationship in one movie title, what would it be and why?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_5"
              value={message_quiz_5}
              onChange={(e) => setMessage_quiz_5(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message_quiz_6">
              What`s one lesson you`ve learned from your partner that has changed your life?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_6"
              value={message_quiz_6}
              onChange={(e) => setMessage_quiz_6(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message_quiz_7">
              How do you keep the spark alive in your relationship after all these years?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_7"
              value={message_quiz_7}
              onChange={(e) => setMessage_quiz_7(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message_quiz_8">
              What`s one dream or goal you and your partner are working towards together?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_8"
              value={message_quiz_8}
              onChange={(e) => setMessage_quiz_8(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message_quiz_9">
              If you could relive one day with your partner, which day would it be and why?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_9"
              value={message_quiz_9}
              onChange={(e) => setMessage_quiz_9(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message_quiz_10">
              Why do you and your partner deserve a romantic Valentine`s dinner with Konnect?
            </label>
            <textarea
              className="form-control"
              id="message_quiz_10"
              value={message_quiz_10}
              onChange={(e) => setMessage_quiz_10(e.target.value)}
              rows={3}
            />
          </div>

          <div className="form-group">
            <label htmlFor="pic">Submit a photo of you and your partner that represents your love story</label>
            <input
              type="file"
              className="form-control-file"
              id="pic"
              onChange={(e) => {
              
                  const file = e.target.files[0]; 
              
                  if (file) {
                    setSelectedFile(file); 
                
            }
              }}
              accept="image/*"
            />
          </div>

          {/* Submit button */}
          <div style={{ float: "right" }}>
            <button type="submit" className="btn btn-warning" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      ) : (
        <div>
          <div className="alert alert-success" role="alert">
            Thank you for taking the time to complete this survey. We appreciate your participation!
          </div>
          <div style={{ width: "100%", maxWidth: 1200, float: "center", display: "flex" }}>
            <a href="../../">
              <button className="btn btn-outline-primary" style={{ fontSize: 14 }}>
                Return to Homepage
              </button>
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

