{/* eslint react/no-unescaped-entities: 0 */}
import '../App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useState,useEffect} from 'react';
  import { getJobsById,jobApplication,} from '../Api';
  import {useParams,Link } from 'react-router-dom'
  import ReactHtmlParser from 'react-html-parser'; 
import {

    FacebookShareButton,
  
    TwitterShareButton,
  

   
  } from "react-share";
  import {
    FacebookIcon,
      TwitterIcon,
      WhatsappIcon
  
  } from "react-share";
  const App = () => {
      const [job,setJob]=useState({})
      const {id} =useParams()
      useEffect(() => {
          const fetchJob = async () => {
            try {
              // setLoading(true)
              getJobsById(id).then(data=>{
    // console.log(data);
  setJob(data)
              });
              // set(data.results);
            
                // setLoading(false)
            } catch (error) {
              // setLoading(false)
              console.log(error);
            }}
            fetchJob()
          
        }, []);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [gender, setGender] = useState('');
    const [loading,setLoading]=useState(false)
    const [region, setRegion] = useState('');
    const [regionOthers, setRegionOthers] = useState('');
    const [dob, setDob] = useState('');
    const [fb, setFb] = useState('');
    const [tw, setTw] = useState('');
    const [insta, setInsta] = useState('');
    const [tiktok,setTiktok]= useState('');
    const [step, setStep] = useState(0);
    const [education, setEducation] = useState('');
    const [proffession, setProfession] = useState('');
    const [yearExp, setYearsExp] = useState('');
    const [skills, setSkills] = useState('');


    const [refName, setRefName] = useState('');
    const [refPhone, setRefPhone] = useState('');
    const [refEmail, setRefEmail] = useState('');
    const [refWorkplace, setRefWorkplace] = useState('');
    const [refPosition, setRefPosition] = useState('');
    const [refRel, setRefRel] = useState('');
    const [error, setError] = useState('');
    const [refName2, setRefName2] = useState('');
    const [refPhone2, setRefPhone2] = useState('');
    const [refEmail2, setRefEmail2] = useState('');
    const [refWorkplace2, setRefWorkplace2] = useState('');
    const [refPosition2, setRefPosition2] = useState('');
    const [refRel2, setRefRel2] = useState('');


    // const [refName3, setRefName3] = useState('');
    // const [refPhone3, setRefPhone3] = useState('');
    // const [refEmail3, setRefEmail3] = useState('');
    // const [refWorkplace3, setRefWorkplace3] = useState('');
    // const [refPosition3, setRefPosition3] = useState('');
    // const [refRel3, setRefRel3] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
     
        if(step==4){
            setLoading(true)
          const formData = new FormData();
          formData.append('job',job.id);
              formData.append('name',name);
              formData.append('phone',phone);
              formData.append('whatsapp',phone2);
              formData.append('email',email);
              formData.append('gender',gender);
              formData.append('dob',dob);
              formData.append('residency',region);
              formData.append('residency_other',regionOthers);
              formData.append('facebook',fb);
              formData.append('twitter',tw);
              formData.append('tiktok',tiktok);
              formData.append('instagram',insta);
              formData.append('education_level',education);
              formData.append('field_of_experience',proffession);
              formData.append('experience_other', null);
              formData.append('year_of_experience',yearExp);
              formData.append('other_skills',skills);
              formData.append('refer1_name',refName);
              formData.append('refer1_phone',refPhone);
              formData.append('refer1_email',refEmail);
              formData.append('refer1_workplace',refWorkplace);
              formData.append('refer1_position',refPosition);
              formData.append('refer1_relationship',refRel);
              formData.append('refer2_name',refName2);
              formData.append('refer2_phone',refPhone2);
              formData.append('refer2_email',refEmail2);
              formData.append('refer2_workplace',refWorkplace2);
formData.append('refer2_position',refPosition2);
formData.append('refer2_relationship',refRel2);
formData.append('cv',selectedFile);
// formData.append('availability',availability);
formData.append('sample_work_link1',sample_work_links);
formData.append('sample_work_link2',sample_work_links_2);
formData.append('sample_work_link3',sample_work_links_2);
formData.append('sample_work_file1',sample_work_1);
// formData.append('sample_work_2',sample_work_2);
// formData.append('sample_work_3',sample_work_3);
// formData.append('refer3_name', null);
// formData.append('refer3_email', null);
// formData.append('refer3_phone', null);
// formData.append('refer3_workplace', null);
// formData.append('refer3_position', null);
// formData.append('refer3_relationship', null);
// const formDataObject = Object.fromEntries(formData.entries());
// console.log(formDataObject)
jobApplication(formData).then(dt=>{
  if(dt?.status==201){
    setStep(step+1)
  }else{
    console.log(dt.response.data)
    const allStrings = [];
var concatenatedString =''
if( dt.response!=null &&  dt.response.data!=null &&  typeof dt.response!=undefined &&  typeof dt.response.data!=undefined){
  for (const key in dt.response.data) {
    if (Object.prototype.hasOwnProperty.call(dt.response.data, key) && Array.isArray(dt.response.data[key])) {
      const concatenatedString = key + '-' + dt.response.data[key].join(', ');
      // console.log(concatenatedString);
      allStrings.push(concatenatedString);
    }
  }
  
  const finalString = allStrings.join(', ');
  concatenatedString=finalString;
}


// console.log(concatenatedString);

    setError('Upload failed, please try again, '+concatenatedString)
  }
       setLoading(false)
})
         

       
        }else{
          var t=step+1
          // console.log(job)
          if(t==3 && job.require_references==false){
            t=4;
          }
          setStep(t)
        }
    }

    const [selectedFile, setSelectedFile] = useState(null);
const [sample_work_links,setSample_work_links]= useState("");
const [sample_work_links_2,setSample_work_links_2]= useState("");
const [sample_work_links_3,setSample_work_links_3]= useState("");
// const [availability,setAvailability]=useState("")
    const handleFileUpload = (event) => {
      const file = event.target.files[0]; 
  
      if (file) {
        setSelectedFile(file); 
    }
}


const [sample_work_1, setSample_work_1] = useState(null);

const handleFileUpload_1 = (event) => {
  const file = event.target.files[0]; 

  if (file) {
    setSample_work_1(file); 
}
}
// const [sample_work_2, setSample_work_2] = useState(null);

// const handleFileUpload_2 = (event) => {
//   const file = event.target.files[0]; 

//   if (file) {
//     setSample_work_2(file); 
// }
// }
// const [sample_work_3, setSample_work_3] = useState(null);

// const handleFileUpload_3= (event) => {
//   const file = event.target.files[0]; 

//   if (file) {
//     setSample_work_3(file); 
// }
// }
    return (
 <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
{/* {step} */}
{step>0 &&<div style={{width:'100%',maxWidth:800,padding:10,margin:10,background:'#f8f8f8'}}>
<h3 style={{color:'orange'}}>{job?.title}</h3>
{/* <table style={{width:'100%'}}>
    <tr>
        <td><b>Category: </b> technicial_support</td>
        <td><b>Date Posted: </b>June 16, 2023</td>
    </tr>
    <tr>
        <td><b>Applications By: </b>June 23, 2023</td>
        <td><b>Location:</b> Zimmerman</td>
    </tr>
    <tr>
    <td><b>Deadline By: </b>23rd June, 2023</td>
     </tr>
</table> */}
<div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>

<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:50,paddingTop:20}}>
<div style={{width:50,height:50,borderRadius:25,background:'orange',position:'relative'}}>
</div>
<div style={{lineHeight:"20px"}}>Personal</div> 
</div>


<div style={{height:5,background:step>1?'orange':'#e5e5e5',flex:1}}></div>
<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:50,paddingTop:20}}>
<div style={{width:50,height:50,borderRadius:25,background:step>2?'orange':'#e5e5e5',position:'relative'}}>
</div>
<div style={{lineHeight:"20px"}}>Professional</div> 
</div>
{job.require_references!=false &&<>
<div style={{height:5,background:step>2?'orange':'#e5e5e5',flex:1}}></div>
<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:50,paddingTop:20}}>
<div style={{width:50,height:50,borderRadius:25,background:step>3?'orange':'#e5e5e5',position:'relative'}}>
</div>
<div style={{lineHeight:"20px"}}>References</div> 
</div></>}
<div style={{height:5,background:step>3?'orange':'#e5e5e5',flex:1}}></div>
<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:50,paddingTop:20}}>
<div style={{width:50,height:50,borderRadius:25,background:step>4?'orange':'#e5e5e5',position:'relative'}}>
</div>
<div style={{lineHeight:"20px"}}>Documents</div> 
</div>
</div>
<hr/>
{step==5 && <div style={{textAlign:'center',minHeight:300}}>
<h4 style={{color:'black'}}>Your Application has been Submitted</h4>

<p>
    
<Link to={'../../'}>Go back to homepage</Link> 
</p>


</div>

}
{step==4 && <div style={{minHeight:300}}>

<form onSubmit={handleSubmit}>
{job.job_type!='Boot Camp' && <>
<h4 style={{color:'black'}}>Upload your CV</h4>
  <p>
  <b>
    
Upload CV (MaxImum file size of 900kB ) 
  </b>
</p>
<div className="form-group">
  <label htmlFor="file" className="required">Upload File</label>
  <input
    required
    type="file"
    accept=".pdf,.doc,.docx"
    onChange={handleFileUpload}
    className="form-control"
    id="file"
  />
</div>
<br/></>}

{job.require_references==false &&<>
<p>
  <b>
    
Please Upload some Sample work
  </b>
</p>
<div className="form-group" style={{flex:1}}>
               
               <label htmlFor="sample_work_links" >Sample work link 1
               
               </label>
               <input   value={sample_work_links} onChange={(e)=>{setSample_work_links(e.target.value)}} className="form-control" id="sample_work_links" placeholder="" />
             
           
             </div>      
             <div className="form-group" style={{flex:1}}>
               
               <label htmlFor="sample_work_links_2" >Sample work link 2
               
               </label>
               <input   value={sample_work_links_2} onChange={(e)=>{setSample_work_links_2(e.target.value)}} className="form-control" id="sample_work_links_2" placeholder="" />
             
           
             </div>      
             <div className="form-group" style={{flex:1}}>
               
               <label htmlFor="sample_work_links_3" >Sample work link 3
               
               </label>
               <input   value={sample_work_links_3} onChange={(e)=>{setSample_work_links_3(e.target.value)}} className="form-control" id="sample_work_links_3" placeholder="" />
             
           
             </div>   
<div className="form-group"  >
  <label htmlFor="file" 
  // className="required"
  >Sample work</label>
  <input
  // required
        type="file"
    accept=".pdf,.doc,.docx"
    onChange={handleFileUpload_1}
    className="form-control"
    id="file"
  />
</div> 
             </>}   

 {/* <div className="form-group">
<label htmlFor="file" >Sample work 2</label>
  <input
     type="file"
    accept=".pdf,.doc,.docx"
    onChange={handleFileUpload_2}
    className="form-control"
    id="file"
  />
</div>
<div className="form-group">
<label htmlFor="file" >Sample work 3</label>
  <input
     type="file"
    accept=".pdf,.doc,.docx"
    onChange={handleFileUpload_3}
    className="form-control"
    id="file"
  />
</div>
<br/> */}
{/* <div className="form-group" style={{flex:1}}>
               
               <label htmlFor="availability" >Please enter your  Availability (e.i Full-time, Part-time)
               
               </label>
               
               <input  value={availability} onChange={(e)=>{setAvailability(e.target.value)}} type="text" className="form-control" id="availability" placeholder=""  />
             </div>     */}
{error!='' && <p style={{width:'100%',textAlign:'center',color:'red'}}>{error}</p>}
<div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
             {/* {!loading ?: <button  className="btn btn-warning">Sending ...</button>} */}
            {!loading && <button type="button" className="btn btn-warning"
             
             onClick={()=>{
              
              if(step>0){
                
                setStep(step-1)
              
              }}}
             >Previous</button>}
             <div style={{width:20}}> </div>

              {loading ?<button type="button" className="btn btn-warning"
             
           
             >Loading...</button>: <button type="submit" className="btn btn-warning"
             
           
             >Submit</button>}
            
              
             </div>
              </form>
</div>

}
{step==3 && <div>
<h4 style={{color:'black'}}>Atleast 2 References</h4>
{/* <hr/> */}
<form onSubmit={handleSubmit}><h5 style={{color:'black'}}><u>Reference 1</u></h5>
                <div className="form-group">
                  <label htmlFor="refName" className="required">Name
                  
                  </label>
                  <input required value={refName} onChange={(e)=>{setRefName(e.target.value)}} type="text" className="form-control" id="refName" placeholder="Enter full name"  />
                </div>
                <div style={{display:'flex',flexDirection:'row'}}>
                <div className="form-group" style={{flex:1}}>
                  <label htmlFor="refPhone" className="required">Phone Number</label>
                  <input required type="tel" maxLength={13} value={refPhone}  onChange={(e)=>{setRefPhone(e.target.value)}}  className="form-control" id="refPhone" placeholder="Enter phone number"  />
                </div>
                <div style={{width:30}}></div>
<div className="form-group" style={{flex:1}}>
                  <label htmlFor="refEmail"  className="required">Email address</label>
                  <input required type="email" value={refEmail}  onChange={(e)=>{setRefEmail(e.target.value)}}  className="form-control" id="refEmail" placeholder="Enter email"  />
                </div>
                </div>
                <div className="form-group" style={{flex:1}}>
               
               <label htmlFor="refWorkplace" className="required">Workplace
               
               </label>
               <input required value={refWorkplace} onChange={(e)=>{setRefWorkplace(e.target.value)}} type="text" className="form-control" id="refWorkplace" placeholder=""  />
             </div>      
    
<div style={{display:'flex',flexDirection:'row'}}>

                <div className="form-group" style={{flex:1}}>
               
                  <label htmlFor="refPosition" className="required">Position
                  
                  </label>
                  <input required value={refPosition} onChange={(e)=>{setRefPosition(e.target.value)}} type="text" className="form-control" id="refPosition" placeholder=""  />
                </div>
<div style={{width:30}}></div>
<div className="form-group" style={{flex:1}}>
  <label htmlFor="refRel" className="required">Relationship </label>
  <select required className="form-control" id="rel"  
  value={refRel} onChange={(e)=>{
    setRefRel(e.target.value);
  }}
  >
 
  <option value="" selected="">-- --</option>

  <option value="Employer">Employer</option>

  <option value="Workmate">Workmate</option>

  <option value="Teacher">Teacher</option>

  <option value="Classmate">Classmate</option>

  <option value="Neighbor">Neighbor</option>

  <option value="Friend">Friend</option>

  <option value="Family">Family</option>


 
  </select>
</div>

</div>    

{/* ////////////ref 2//////////// */}

<hr/>
<h5 style={{color:'black'}}><u>Reference 2</u></h5>
                <div className="form-group">
                  <label htmlFor="refName2" className="required">Name
                  
                  </label>
                  <input required value={refName2} onChange={(e)=>{setRefName2(e.target.value)}} type="text" className="form-control" id="refName2" placeholder="Enter full name"  />
                </div>
                <div style={{display:'flex',flexDirection:'row'}}>
                <div className="form-group" style={{flex:1}}>
                  <label htmlFor="refPhone2" className="required">Phone Number</label>
                  <input required type="tel" maxLength={13} value={refPhone2}  onChange={(e)=>{setRefPhone2(e.target.value)}}  className="form-control" id="refPhone2" placeholder="Enter phone number"  />
                </div>
                <div style={{width:30}}></div>
<div className="form-group" style={{flex:1}}>
                  <label htmlFor="refEmail2"  className="required">Email address</label>
                  <input required type="email" value={refEmail2}  onChange={(e)=>{setRefEmail2(e.target.value)}}  className="form-control" id="refEmail2" placeholder="Enter email"  />
                </div>
                </div>
                <div className="form-group" style={{flex:1}}>
               
               <label htmlFor="refWorkplace2" className="required">Workplace
               
               </label>
               <input required value={refWorkplace2} onChange={(e)=>{setRefWorkplace2(e.target.value)}} type="text" className="form-control" id="refWorkplace2" placeholder=""  />
             </div>      
    
<div style={{display:'flex',flexDirection:'row'}}>

                <div className="form-group" style={{flex:1}}>
               
                  <label htmlFor="refPosition2" className="required">Position
                  
                  </label>
                  <input required value={refPosition2} onChange={(e)=>{setRefPosition2(e.target.value)}} type="text" className="form-control" id="refPosition2" placeholder=""  />
                </div>
<div style={{width:30}}></div>
<div className="form-group" style={{flex:1}}>
  <label htmlFor="refRel2" className="required">Relationship </label>
  <select required className="form-control" id="rel"  
  value={refRel2} onChange={(e)=>{
    setRefRel2(e.target.value);
  }}
  >
 
  <option value="" selected="">-- --</option>

  <option value="Employer">Employer</option>

  <option value="Workmate">Workmate</option>

  <option value="Teacher">Teacher</option>

  <option value="Classmate">Classmate</option>

  <option value="Neighbor">Neighbor</option>

  <option value="Friend">Friend</option>

  <option value="Family">Family</option>


 
  </select>
</div>
</div>

<div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
             {/* {!loading ?: <button  className="btn btn-warning">Sending ...</button>} */}
             <button type="button" className="btn btn-warning"
             
             onClick={()=>{if(step>0){setStep(step-1)}}}
             >Previous</button>
             <div style={{width:20}}></div>
              <button type="submit" className="btn btn-warning"
             
           
             >Next</button>
             </div>
              </form>  
</div>
}
{step==2 && <div>
<h4 style={{color:'black'}}>Academic Information</h4>
   <form onSubmit={handleSubmit}><div className="form-group">
  <label htmlFor="selectOption" className="required">Highest Level of Education</label>
  <select required className="form-control" id="education" value={education} onChange={(e)=>{
    setEducation(e.target.value);
  }}>

  <option value="" selected="">-- --</option>

  <option value="Doctoral">Doctoral</option>

  <option value="Master">Master</option>

  <option value="Bachelor">Bachelor</option>

  <option value="Diploma">Diploma</option>

  <option value="Vocational">Vocational</option>

  <option value="High School">High School</option>

  <option value="Primary  School">Primary  School</option>

  </select>
</div>
<div className="form-group">
  <label htmlFor="selectOption" className="required">Major Field of Experience</label>
  <select required className="form-control" id="proffession" value={proffession} onChange={(e)=>{
    setProfession(e.target.value);
  }}>
  <option value="" selected="">-- --</option>

  <option value="Engineering &amp; Software">Engineering &amp; Software</option>

  <option value="Sales, Marketing &amp; PR">Sales, Marketing &amp; PR</option>

  <option value="Admin, HR, Finance &amp; Legal">Admin, HR, Finance &amp; Legal</option>

  <option value="Operation &amp; Logistics">Operation &amp; Logistics</option>

  <option value="Creative, music, visual &amp; performing arts">Creative, music, visual &amp; performing arts</option>

  <option value="Teaching, Training &amp; Research">Teaching, Training &amp; Research</option>

  <option value="Technical (Electrical, Mechanical, Building, etc.)">Technical (Electrical, Mechanical, Building, etc.)</option>

  <option value="Medical &amp; Health">Medical &amp; Health</option>

  <option value="Services (Cleaning, Hospitality, Transport, Tourism, etc.)">Services (Cleaning, Hospitality, Transport, Tourism, etc.)</option>

  <option value="Other">Other</option>

  </select>

 
</div> 

<div className="form-group">
                  <label htmlFor="name" className="required">Years of Work Experience
                  
                  </label>
                  <input required value={yearExp} onChange={(e)=>{setYearsExp(e.target.value)}} type="number" className="form-control" id="exp" placeholder=""  />
                </div>
                <div className="form-group">
                  <label htmlFor="skills" >Cover Letter</label>
                  <textarea  value={skills}  onChange={(e)=>{setSkills(e.target.value)}} className="form-control" id="skills" rows="3" placeholder="" ></textarea>
                </div>
             

<div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
             {/* {!loading ?: <button  className="btn btn-warning">Sending ...</button>} */}
             <button type="button" className="btn btn-warning"
             
             onClick={()=>{if(step>0){
              var t=step-1
              // console.log(job)
              if(t==3 && job.require_references==false){
                t=4;
              }
              setStep(t)}}}
             >Previous</button>
             <div style={{width:20}}></div>
              <button type="submit" className="btn btn-warning"
             
           
             >Next</button>
             </div>
              </form>
</div>}
{step==1 && <div>
<h4 style={{color:'black'}}>Personal Information</h4>

<form onSubmit={handleSubmit}>

                <div className="form-group">
                  <label htmlFor="name" className="required">Name
                  
                  </label>
                  <input required value={name} onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control" id="name" placeholder="Enter your full name"  />
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                <div className="form-group" style={{flex:1,minWidth:150}}>
                  <label htmlFor="phone" className="required">Phone Number</label>
                  <input required type="tel" maxLength={13} value={phone}  onChange={(e)=>{setPhone(e.target.value)}}  className="form-control" id="phone" placeholder="Enter your phone number"  />
                </div>
                <div style={{width:30}}></div>
<div className="form-group" style={{flex:1 ,minWidth:150}}>
                  <label htmlFor="phone2">
                    Whatsapp <span>
      <WhatsappIcon size={20} round={true} />
      </span>
                    
                  (if different)</label>
                  <input  type="tel" maxLength={13} value={phone2}  onChange={(e)=>{setPhone2(e.target.value)}}  className="form-control" id="phone2" placeholder="Whatsapp number"  />
                </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email"  className="required">Email address</label>
                  <input required type="email" value={email}  onChange={(e)=>{setEmail(e.target.value)}}  className="form-control" id="email" placeholder="Enter your email"  />
                </div>
    
<div style={{display:'flex',flexDirection:'row'}}>

                <div className="form-group" style={{flex:1}}>
  <label htmlFor="gender" className="required">Gender</label>
  <select required className="form-control" id="gender"  
  value={gender} onChange={(e)=>{
    setGender(e.target.value);
  }}
  >
  <option value=''>-- --</option>
    <option value={'male'}>Male</option>
    <option value={'female'}>Female</option>
 
  </select>
</div>
<div style={{width:30}}></div>
<div className="form-group" style={{flex:1}}>
  <label htmlFor="dob" className="required">Date of Birth</label>
  <input
    required
    type="date"
    value={dob}
    onChange={(e) => { setDob(e.target.value) }}
    className="form-control"
    id="dob"
    placeholder="Enter your date of birth"
  />
</div>
</div>
<div className="form-group">
  <label htmlFor="region" className="required">Region</label>
  <select required className="form-control" id="region" value={region} onChange={(e)=>{
    setRegion(e.target.value);
  }}>
  <option value=''>-- --</option>
  <option value="G44">Githurai 44</option> 
    <option value="G45">Githurai 45</option>
    <option value="Zimmerman">Zimmerman</option>
    <option value="Roysambu">Roysambu/Mirema</option>  
    <option value="Kahawa_West">Kahawa West</option>  
    <option value="Hunters">Hunters</option>  
    <option value="Mwiki">Mwiki</option>  
    <option value="Sunton">Sunton</option> 
    <option value="Kasarani">Kasarani</option>  
    <option value="Lucky_Summer">Lucky Summer</option>  
    <option value="kahawa_wendani">Kahawa Wendani</option>  
    <option value='Umoja_1'>Umoja 1</option>
<option value='Umoja_2'>Umoja 2</option>
<option value='Umoja_3'>Umoja 3</option>
<option value='Pipeline'>Pipeline</option>
<option value='Kayole'>Kayole</option>
<option value='Rongai'>Rongai</option>
<option value='Kibera'>Kibera</option>
    <option value="Other">Other</option>
  </select>
</div>
<div className="form-group">
                  <label htmlFor="regionOthers" >Specify residency if "Other" is selected
                  
                  </label>
                  <input  value={regionOthers} onChange={(e)=>{setRegionOthers(e.target.value)}} type="text" className="form-control" id="regionOthers" placeholder=""  />
                </div>
          
                <div style={{display:'flex',flexDirection:'row'}}>
                   {job.social_fb &&<>   <div className="form-group" style={{flex:1}}>
                  <label htmlFor="fb">
                  Facebook <span>
      <FacebookIcon size={20} round={true} />
      </span>
                    
                 </label>
                  <input  type="text"  value={fb}  onChange={(e)=>{setFb(e.target.value)}}  className="form-control" id="fb" placeholder=""  />
                </div>
                </>}
                {job.social_twitter &&<>
                <div style={{width:20}}></div>
<div className="form-group" style={{flex:1}}>
                  <label htmlFor="tw">
                  Twitter <span>
      <TwitterIcon size={20} round={true} />
      </span>
                    </label>
                  <input  type="text"  value={tw}  onChange={(e)=>{setTw(e.target.value)}}  className="form-control" id="tw" placeholder=""  />
                </div></>}
                {job.social_instagram &&<>
                <div style={{width:20}}></div>
<div className="form-group" style={{flex:1}}>
                  <label htmlFor="insta">
                  Instagram <span>
                  <i size={30}className="fab fa-instagram"></i>
     
      </span>
                    </label>
                  <input  type="text"  value={insta}  onChange={(e)=>{setInsta(e.target.value)}}  className="form-control" id="insta" placeholder=""  />
                </div></>}
                {job.social_tiktok &&<>
                <div style={{width:20}}></div>
<div className="form-group" style={{flex:1}}>
                  <label htmlFor="tiktok">
                  Tiktok <span>
                  <i size={30}className="fab fa-tiktok"></i>
     
      </span>
                    </label>
                  <input  type="text"  value={tiktok}  onChange={(e)=>{setTiktok(e.target.value)}}  className="form-control" id="tiktok" placeholder=""  />
                </div></>}
                </div>
                <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
             {/* {!loading ?: <button  className="btn btn-warning">Sending ...</button>} */}
             <button type="button" className="btn btn-warning"
             
             onClick={()=>{if(step>0){setStep(step-1)}}}
             >Previous</button>
             <div style={{width:20}}></div>
              <button type="submit" className="btn btn-warning"
             
           
             >Next</button>
             </div>
              </form>
</div>}

</div>}
{step==0 && <div style={{width:'100%',maxWidth:800,padding:10,margin:10,background:'#f8f8f8'}}>
<h3 style={{color:'orange'}}>{job?.title}</h3>
<table style={{width:'100%'}}>
    <tr>
        <td><b>Category: </b> {job?.job_category}</td>
        <td><b>Date Posted:  </b> { new Date(job?.date_created?.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</td>
    </tr>
    <tr>
        <td><b>Applications By: </b> { new Date(job?.deadline?.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</td>
        <td><b>Location:</b> {job?.location}</td>
    </tr>
    <tr>
    <td><div className="d-flex flex-row fs-12">
                 
                 <div className="like p-2 cursor"><i className="fa fa-share"></i>
                 <span className="ml-1" >
                   Share
                   <span style={{margin:2}}>
                    <FacebookShareButton url={window.location.origin+'/job/apply/'+job?.slug}  quote={job?.title}>
                   <FacebookIcon size={32} round={true} />
</FacebookShareButton>
                 </span>
                 <span style={{margin:2}}>
                 <TwitterShareButton url={window.location.origin+'/job/apply/'+job?.slug} title={job?.title}>
<TwitterIcon size={32} round={true} />
</TwitterShareButton>
                 </span>       
                 <span style={{margin:2}}>
                 <Link  to={'whatsapp://send?text='+window.location.origin+'/job/apply/'+job?.slug}>
<WhatsappIcon size={32} round={true} />
</Link>
                 </span>  
                   </span></div>
            
                 

             </div></td>
           <td><button onClick={()=>{setStep(1)}} className='applybtn' style={{background:'orange',float:'left',border:'0px',color:'white',padding:5,width:100,borderRadius:10}}>APPLY </button></td> 
     
    </tr>
</table>

<hr/>
{job.job_type!='Boot Camp' &&
<h4 style={{color:'black'}}>Job Description</h4>}
{ReactHtmlParser(job.job_description) }
 
    {job.job_type!='Boot Camp' && <>
       <hr/>
       <h4 style={{color:'black'}}>Job Requirement</h4>

    {ReactHtmlParser(job.job_requirements) }
<hr/>

    <h4 style={{color:'black'}}>Responsibilities</h4>
    {ReactHtmlParser(job.responsibilities) }
      </>}
   

<p>&nbsp;</p>

<p><b>Deadline: { new Date(job?.deadline?.substring(0,10)).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</b></p>
             


             <div   style={{width:'100%',height:100}}><button className='applybtn'  onClick={()=>{setStep(1)}}  style={{float:'right',border:'0px',color:'white',padding:10,width:100,borderRadius:10}}>APPLY </button></div>

</div>}

    </div>

  );
};

export default App;