
// import "survey-core/defaultV2.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
// import {db} from './firebase-config'
import { useState,useEffect } from "react";
import ReactHtmlParser from 'react-html-parser'; 
// import { collection,getDoc, query, where,updateDoc,doc,addDoc } from '@firebase/firestore';
import { getOneSurvey2,saveSurveyResult2 } from '../../Api';
import React from 'react'
import { useParams } from 'react-router-dom';
const defaultJson = {
  pages: [{
    name: "Name",
    elements: []
  }]
};
// const navigate=useNavigeate();
const SurveyComponent=()=> {
  
  const [schema,setSchema]=useState(defaultJson)
  const { id } = useParams();
  const { ticket } = useParams();
  const [loading,setLoading] = useState(false);
const [data,setData]=useState({});
  // const navigate=useNavigate();
  const [done,setDone]=useState(false)
  const [complete,setComplete]=useState(false)
  const fetchOneSurvey = async () => {
    setLoading(true)
    try {
      getOneSurvey2(id).then(data2=>{
              // console.log(data)
              setData(data2)
              setSchema(data2)
                 const survey_item = localStorage.getItem(data2.id);
// localStorage.setItem(data.id, false);
   
    if (survey_item) {
 setDone(true)
// 
    } 

               setLoading(false)
      });
    
      setLoading(false)
    //  
    } catch (error) {
      // Handle error
    }}


    
  useEffect(()=>{

  
  fetchOneSurvey()
    },[])

    const onSurveySubmit = (survey) => {
console.log({'survey' :id,'responses':survey.data,ticketId:ticket});

if(typeof ticket!=undefined){

   saveSurveyResult2({'survey' :id,'response':survey.data,ticketId:ticket}).then((st)=>{
    setComplete(true)
    console.log(st)
    // setDone(true)
// localStorage.setItem(data.id, true);
  })

}

 
};
  const survey = new Model(schema);
  return(
    <div style={{minHeight:'60vh'}}> {loading &&  <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',color:'white',position:'fixed',top:0,left:0,background:'rgba(0,0,0,0.5)'}}>
<strong>Loading...</strong>

        </div>}
   
     {done==false && complete==false && <div style={{zoom:0.8}}><Survey model={survey} onComplete={onSurveySubmit} /></div>}
   {complete==false && done==true && 
<div style={{width:'100%',textAlign:'center',padding:10,paddingTop:100,paddingBottom:100,background:'#f8f8f8'}}><h2>You have already participated in this survey</h2>

<br/>
<button className='btn btn-outline-primary' onClick={()=>{
     window.location.href = '/';
}}>Go to Homepage</button>
</div>}

{(complete==true &&  done==false) && <div style={{width:'100%',textAlign:'center',padding:10,paddingTop:100,paddingBottom:100,background:'#f8f8f8'}}>{ReactHtmlParser(data.completedHtml)}

<br/>
<button className='btn btn-outline-primary' onClick={()=>{
     window.location.href = '/';
}}>Go to Homepage</button>
</div>}
</div>
  )

  
}


export default  SurveyComponent