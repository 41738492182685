import React, { useState } from 'react';
import { postEventApplication } from '../Api';
import PropTypes from 'prop-types';
export default function EventApplication(props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [region, setRegion] = useState('');
  const [regionOthers, setRegionOthers] = useState('');
  const [open_quiz_1, setOpen_quiz_1] = useState('');// likely are you to recommend Konnect
  const [open_quiz_2, setOpen_quiz_2] = useState(''); // you rate the overall speed, reliability, and consistency 
  const [open_quiz_3, setOpen_quiz_3] = useState(''); // rate the knowledge, professionalism and ability of our customer support 
  const [open_quiz_4, setOpen_quiz_4] = useState(''); //  likely are you to continue using Konnect Internet over the next 3 months
  const [open_quiz_5, setOpen_quiz_5] = useState([]); // packages do you mostly subscribe to
  const [open_quiz_6, setOpen_quiz_6] = useState([]); //preferred mode of communication for support and updates
  const [open_quiz_6_other, setOpen_quiz_6_other] = useState('');
  // const [open_quiz_7, setOpen_quiz_7] = useState([]);
  const [open_quiz_8, setOpen_quiz_8] = useState('');  //rate your experience with the Konnect Internet Super App
  const [open_quiz_9, setOpen_quiz_9] = useState([]); //features of the Konnect Internet Super App do you find most useful
  const [open_quiz_9_other, setOpen_quiz_9_other] = useState('');
  const [message_quiz_1, setMessage_quiz_1] = useState("")
  const [open_quiz_10, setOpen_quiz_10] = useState(false); //terms and conditions



  const handleCheckboxChange = (setFunc) => (event) => {
    const { value, checked } = event.target;
    setFunc((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleRadioChange = (setFunc) => (event) => {
    setFunc(event.target.value);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = {
      title: props?.title,
      phone: phone,
      name: name,
      residency: region,
      residency_other: regionOthers,
      // open_quiz_1: open_quiz_1.join(', '),
      open_quiz_1: open_quiz_1,
      open_quiz_2: open_quiz_2,
      open_quiz_3: open_quiz_3,
      open_quiz_4: open_quiz_4,
      open_quiz_5: open_quiz_5.join(', '),
      open_quiz_6: open_quiz_6.includes('Other')
        ? [...open_quiz_6.filter((option) => option !== "Other"), `Other: ${open_quiz_6_other}`].join(', ')
        : open_quiz_6.join(', '),
      // open_quiz_7: open_quiz_7,
      open_quiz_8: open_quiz_8,
      open_quiz_9: open_quiz_9.includes('Other')
        ? [...open_quiz_9.filter((option) => option !== "Other"), `Other: ${open_quiz_9_other}`].join(', ')
        : open_quiz_9.join(', '),
      open_quiz_10: open_quiz_10 ? "Yes" : "No",
      message_quiz_1: message_quiz_1,

    };

    console.log(data);

    postEventApplication(data).then((msg) => {
      setName('');
      setPhone('');
      setRegion('');
      setRegionOthers('');
      // setOpen_quiz_1([]);
      setOpen_quiz_1('');
      setOpen_quiz_2('');
      setOpen_quiz_3('')
      setOpen_quiz_4('');
      setOpen_quiz_5([]);
      setOpen_quiz_6('');
      // setOpen_quiz_7('');
      setOpen_quiz_8('');
      setOpen_quiz_9([]);
      setOpen_quiz_10(false);
      setMessage_quiz_1('');
      setLoading(false);

      if (msg !== false) {
        setSuccess(true);
        props.onFormSubmitSuccess();
      }
    });
  };

  return (
    <div style={{ width: '100%', maxWidth: 800, backgroundColor: !success ? '#e5e5e5' : 'white', padding: 10, borderRadius: 10 }}>
      {success === false && (
        <form onSubmit={handleSubmit}>
           <h4>Participate in the Survey </h4>

          {/* Name */}
         {/* <div className="form-group">
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your full name"
            />
          </div>*/}

          {/* Phone */}
          <div className="form-group">
            <label htmlFor="phone" className="required">
              Your Phone registered with Konnect Internet
            </label>
            <input
              required
              type="tel"
              maxLength={13}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              id="phone"
              placeholder="07XXXXXXXX"
            />
          </div>

          {/* Region */}
          <div className="form-group">
            <label htmlFor="region" className="required">
              Your Residence
            </label>
            <select
              required
              className="form-control"
              id="region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            >
              <option value="">-- --</option>
              {[
                ['Githurai_44', 'Githurai 44'],
                ['Githurai_45', 'Githurai 45'],
                ['Zimmerman', 'Zimmerman'],
                ['Roysambu_Mirema', 'Roysambu/Mirema'],
                ['Kahawa_West', 'Kahawa West'],
                ['Kahawa_Wendani', 'Kahawa Wendani'],
                ['Lucky_Summer_Baba_Dogo_Ngumba', 'Lucky Summer/Baba Dogo/Ngumba'],
                ['Kasarani', 'Kasarani'],
                ['Hunters', 'Hunters'],
                ['Mwiki', 'Mwiki'],
                ['Sunton', 'Sunton'],
                ['Kariobangi_South', 'Kariobangi South'],
                ['Mowlem_Umoja_3', 'Mowlem/Umoja 3'],
                ['Kibera', 'Kibera'],
                ['Umoja', 'Umoja'],
                ['Pipeline', 'Pipeline'],
                ['Kayole', 'Kayole'],
                // ['Other', 'Other']
              ].map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
              ))}
              <option value="Other">Other</option>
            </select>
          </div>

          {region === 'Other' && (
            <div className="form-group">
              <label htmlFor="regionOthers">
                Specify residency if `Other` is selected
              </label>
              <input
                value={regionOthers}
                onChange={(e) => setRegionOthers(e.target.value)}
                type="text"
                className="form-control"
                id="regionOthers"
                placeholder=""
              />
            </div>
          )}


          {/*======================================== */}

<div className="form-group">
  <label className="required">
    1. How likely are you to recommend Konnect Internet to a relative, friend, colleague or neighbor?
  </label>
  <br />
  {["Absolutely", "VeryLikely", "Likely/Maybe", "Unlikely", "Very (unlikely)", "Absolutely no"].map((option) => (
    <div key={option}>
      <input
        type="radio"
        className="input_test"
        id={`open_quiz_1_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}
        name="open_quiz_1"
        value={option}
        checked={open_quiz_1 === option.toString()}
        onChange={handleRadioChange(setOpen_quiz_1)}
      />
      <label htmlFor={`open_quiz_1_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}>{option}</label>
      <br />
    </div>
  ))}
</div>
          

            <div className="form-group">
  <label className="required">
    2. How would you rate the overall speed, reliability, and consistency of your Konnect Internet connection?
  </label>
  <br />
  {["Excellent", "Good", "Average", "Poor"].map((option) => (
    <div key={option}>
      <input
        type="radio"
        className="input_test"
        id={`open_quiz_2_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}
        name="open_quiz_2"
        value={option}
        checked={open_quiz_2 === option.toString()}
        onChange={handleRadioChange(setOpen_quiz_2)}
      />
      <label htmlFor={`open_quiz_2_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}>{option}</label>
      <br />
    </div>
  ))}
</div>



          <div className="form-group">
  <label className="required">
    3. How would you rate the professionalism and problem-solving skills of our customer support and technical staff?
  </label>
  <br />
  {["Excellent", "Good", "Average", "Poor"].map((option) => (
    <div key={option}>
      <input
        type="radio"
        className="input_test"
        id={`open_quiz_3_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}
        name="open_quiz_3"
        value={option}
        checked={open_quiz_3 === option.toString()}
        onChange={handleRadioChange(setOpen_quiz_3)}
      />
      <label htmlFor={`open_quiz_3_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}>{option}</label>
      <br />
    </div>
  ))}
</div>


          <div className="form-group">
  <label className="required">
    4. How likely are you to continue using Konnect Internet over the next 3 months?
  </label>
  <br />
  {["Absolutely", "Likely", "Maybe", "Unlikely", "Very unlikely"].map((option) => (
    <div key={option}>
      <input
        type="radio"
        className="input_test"
        id={`open_quiz_4_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}
        name="open_quiz_4"
        value={option}
        checked={open_quiz_4 === option.toString()}
        onChange={handleRadioChange(setOpen_quiz_4)}
      />
      <label htmlFor={`open_quiz_4_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}>{option}</label>
      <br />
    </div>
  ))}
</div>


          
 

          <div className="form-group">
            <label className="required">
              5. Which Konnect Internet package(s) do you mostly subscribe to? (Select up to 2)
            </label>
            <br />
            {['Hourly (Ksh 10/20)', 'Daily (Ksh 50/80/140)', 'Weekly Solo (Ksh 280)', 'Weekly (Ksh 380)', 'Monthly Solo (Ksh 720)', 'Monthly (Ksh 1000-2000)', 'Quarterly (Ksh 2700-5300)'].map((option) => (
              <div key={option}>
                <input
                  type="checkbox"
                  className="input_test"
                  id={`open_quiz_5_${option.replace(/\s+/g, '_')}`}
                  name="open_quiz_5"
                  value={option}
                  checked={open_quiz_5.includes(option)}
                  onChange={handleCheckboxChange(setOpen_quiz_5)}
                />
                <label htmlFor={`open_quiz_5_${option.replace(/\s+/g, '_')}`}>{option}</label>
                <br />
              </div>
            ))}
          </div>


          

          <div className="form-group">
            <label className="required">
              6. What is your preferred way to reach Konnect Customer Support? (Select up to 2)
            </label>
            <br />
            {['Konnect App', 'Phone Call', '"Chat Us" in karibu.sasakonnect.net', 'WhatsApp', 'SMS/Text', 'Social Media (Facebook, Twitter, etc.)', 'Other'].map((option) => (
              <div key={option}>
                <input
                  type="checkbox"
                  className="input_test"
                  id={`open_quiz_6_${option.replace(/\s+/g, '_')}`}
                  name="open_quiz_6"
                  value={option}
                  checked={open_quiz_6.includes(option)}
                  onChange={handleCheckboxChange(setOpen_quiz_6)}
                />
                <label htmlFor={`open_quiz_6_${option.replace(/\s+/g, '_')}`}>{option}</label>
                <br />
              </div>
            ))}
            {open_quiz_6.includes('Other') && (
              <input
                type="text"
                className="form-control"
                id="open_quiz_6_other"
                name="open_quiz_6_other"
                value={open_quiz_6_other}
                onChange={(e) => setOpen_quiz_6_other(e.target.value)}
                placeholder="Please specify"
              />
            )}
          </div>

          
          <div className="form-group">
  <label className="required">
    7. How would you rate your experience with the Konnect App?
  </label>
  <br />
  {["Excellent", "Good", "Average", "Poor", "Yet to download it from Google Play Store"].map((option) => (
    <div key={option}>
      <input
        type="radio"
        className="input_test"
        id={`open_quiz_8_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}
        name="open_quiz_8"
        value={option}
        checked={open_quiz_8 === option.toString()}
        onChange={handleRadioChange(setOpen_quiz_8)}
      />
      <label htmlFor={`open_quiz_8_${option.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()}`}>{option}</label>
      <br />
    </div>
  ))}
</div>

          




          <div className="form-group">
            <label className="required">
              8. What features of the Konnect App do you find most useful? (Please select all that apply)
            </label>
            <br />
            {['Extra Internet time offers', 'Add/change devices', 'Payment options', 'Customer support & Chat', 'Other'].map((option) => (
              <div key={option}>
                <input
                  type="checkbox"
                  className="input_test"
                  id={`open_quiz_9_${option.replace(/\s+/g, '_')}`}
                  name="open_quiz_9"
                  value={option}
                  checked={open_quiz_9.includes(option)}
                  onChange={handleCheckboxChange(setOpen_quiz_9)}
                />
                <label htmlFor={`open_quiz_9_${option.replace(/\s+/g, '_')}`}>{option}</label>
                <br />
              </div>
            ))}
            {open_quiz_9.includes('Other') && (
              <input
                type="text"
                className="form-control"
                id="open_quiz_9_other"
                name="open_quiz_9_other"
                value={open_quiz_9_other}
                onChange={(e) => setOpen_quiz_9_other(e.target.value)}
                placeholder="Please specify"
              />
            )}
          </div>

        <div className="form-group">
            <label htmlFor="message_quiz_1">
             10. We appreciate any additional feedback or suggestions to help us serve you better:
            </label>
            <textarea
              className="form-control"
              id="message_quiz_1"
              value={message_quiz_1}
              onChange={(e) => setMessage_quiz_1(e.target.value)}
              rows={3}
            />
          </div>

        
        <div className="form-group">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="id_open_quiz_10"
              checked={open_quiz_10}
              onChange={(e) => setOpen_quiz_10(e.target.checked)}
              required
              />
            <label className="form-check-label" htmlFor="id_open_quiz_10">
              I agree that winners from the anniversary will come for their gifts at the Konnect ZMM hub and Konnect will take photos and a testimonial video about their experience with Konnect Internet.
            </label>
          </div>
        </div>


































          {/* Submit button */}
          <div style={{ float: 'right' }}>
            {loading ? (
              <button type="button" className="btn btn-warning">
                Loading...
              </button>
            ) : (
              <button type="submit" className="btn btn-warning">
                Submit Survey
              </button>
            )}
          </div>
        </form>
      )}

      {/*
      {success && (
        <div className={`alert alert-success`} role="alert">
          Thank you for taking the time to complete this survey.
        </div>
      )}
      */}



{/**----------------------- FOR KONNECT ANNIVERSARY ONLY --------- */}

 {/*     
{success && (
  <div className={`alert alert-success`} role="alert">
    <p className="font-bold">✅ Thank You for Your Feedback!</p>
          <p>🎁 <strong>You&apos;re Now Entered in the Lucky Draw!</strong> Winners will be drawn <strong>LIVE on Sunday, 23rd March, 4-6 PM</strong> — don&apos;t miss the announcements, Q&A, and live music! 🎶</p>
          <p>🚰 <strong>Claim Your FREE 10L or 20L Konnect Water (purified drinking water)!</strong> We&apos;ll send you an SMS with instructions on how to get it soon.</p>
    <p>🍔 <strong>More Rewards Await!</strong> As part of our anniversary celebration, enjoy <strong>exclusive Konnect Food+ deals</strong> with amazing discounts on electronics!</p>
    <div className="mt-3">
      <a href="https://food.sasakonnect.net/10-weekly-offers" className="btn btn-primary mr-4">GRAB SUPER DEALS!</a>
      <a href="karibu.sasakonnect.net" className="btn btn-secondary ml-2">BACK</a>
    </div>
  </div>
      )}
 */}
      
      
{success && (
  <div style={{ textAlign: 'center', padding: '20px' }}>
    <div className="alert alert-success" role="alert">
 
        <p className="font-bold">✅ Thank You for Your Feedback!</p>
          <p>🎁 <strong>You&apos;re Now Entered in the Lucky Draw!</strong> Winners will be drawn <strong>LIVE on Sunday, 23rd March, 4-6 PM</strong> — don&apos;t miss the announcements, Q&A, and live music! 🎶</p>
          <p>🚰 <strong>Claim Your FREE 10L or 20L Konnect Water (purified drinking water)!</strong> We&apos;ll send you an SMS with instructions on how to get it soon.</p>
        <p>🍔 <strong>More Rewards Await!</strong> As part of our anniversary celebration, enjoy <strong>exclusive Konnect Food+ deals</strong> with amazing discounts on electronics!</p>

            
   
    </div>



    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
      <a
        href="https://food.sasakonnect.net/10-weekly-offers"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          padding: '10px 20px',
          backgroundColor: '#28a745',
          color: 'white',
          textDecoration: 'none',
          borderRadius: '5px',
        }}
      >
        GRAB SUPER DEALS!
      </a>
      <a
        href="https://karibu.sasakonnect.net"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: 'white',
          textDecoration: 'none',
          borderRadius: '5px',
        }}
      >
        BACK
      </a>
    </div>

    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap', // Allow wrapping of items
        justifyContent: 'center',
        gap: '10px',
        marginTop: '20px',
      }}
    >
      {[
        { src: "https://cdn.sasakonnect.net/content?bucket=landing-page&file=5287e1f4-33a2-4fa4-9f53-5ba2e3c1cf2f", url: 'https://food.sasakonnect.net/electronics/3454-ps23-power-bank-225w-fast-charge-20000mah-white.html' },
        { src: "https://cdn.sasakonnect.net/content?bucket=landing-page&file=ad9cf4ae-e12c-4b08-8222-d9f1a97644f9", url: 'https://food.sasakonnect.net/electronics/3675-refurbished-hp-elitebook-840-g1-laptop-intel-core-i5-8gb-ram-256gb-hdd-rom-14-inch-fhd-display.html' },
        { src: "https://cdn.sasakonnect.net/content?bucket=landing-page&file=e66c59e1-b211-4999-97b7-e5cea7fdc990", url: 'https://food.sasakonnect.net/appliances/3477-jd-7807-tungsten-filament-solar-sensor-light.html' },
       
        { src: "https://cdn.sasakonnect.net/content?bucket=landing-page&file=1feb4d4c-d233-4b28-9247-87f4e6280f97", url: 'https://food.sasakonnect.net/electronics/3383-243-techno-camon-20-premier-667-8gb-ram-512gb-rom.html#/63-color-white' },
        { src: "https://cdn.sasakonnect.net/content?bucket=landing-page&file=6dc54137-939c-4862-91a3-ac4dfab9f983", url: 'https://food.sasakonnect.net/household/3606-usb-smart-electric-water-dispenser-water-pump.html' },
        { src: "https://cdn.sasakonnect.net/content?bucket=landing-page&file=fcea564b-57ab-41f3-96d1-7c0422b3c803", url: 'https://food.sasakonnect.net/electronics/3479-16gb-memory-card.html' },

      ].map((image, index) => (
        <a
          key={index}
          href={image.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            flex: '1 1 calc(50% - 20px)', // Two images per row on mobile
            maxWidth: 'calc(50% - 20px)', // Two images per row on mobile
            textAlign: 'center',
          }}
        >
          <img
            src={image.src}
            alt={`Placeholder ${index + 1}`}
            style={{
              width: '100%', // Make images responsive
              height: 'auto', // Maintain aspect ratio
              borderRadius: '10px',
              cursor: 'pointer',
            }}
          />
        </a>
      ))}
    </div>
  </div>
)}
{/**----------------------- FOR KONNECT ANNIVERSARY ONLY --------- */}

      
      




    </div>
  );
}
EventApplication.propTypes = {
  title: PropTypes.string.isRequired, // Validate that `title` is a required string
  onFormSubmitSuccess: PropTypes.func.isRequired,
};