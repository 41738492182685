/* eslint eqeqeq: 0 */
{/* eslint react/no-unescaped-entities: 0 */}
/* eslint-disable react/prop-types */import '../App.css';
// import { google } from 'googleapis';
import VerticalVideos from './verticalVideos'
import React,{useState,useEffect} from 'react';
import KonnectYoutube from './konnect_youtube'
import KonnectJikoni from './/konnect_jikoni'
import { Helmet } from 'react-helmet';
// import YoutubeLIke from './youtubeLike'
const Videos = () => {
    const [show,setShow]=useState(true)
  useEffect(() => {
    window.scrollTo(0, 0);
  
   },[])
   useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.onload = () => {
      // renderSubscribeButton();
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // const renderSubscribeButton = () => {
  //   window.gapi?.youtube?.subscribeButton?.render({
  //     channel: 'boAWearcNSPGVduir8zA',
  //     layout: 'default',
  //   });
  // };

  return (
    <div style={{flex:1,paddingTop:20,width:'100%',maxWidth:'100vw',minHeight:'60vh'}}>
       <Helmet>
  <title> {'Karibu Konnect | Videos'}</title>
  <meta name="description" content='Welcome to Konnect'/>
</Helmet>
    {show &&  <div>
      <div style={{width:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'center',alignItems:'start'}}
   
      >  
      {/* <div style={{ height:100, overflow: 'hidden !important'}}> */}

   
      {/* <div>dfd</div> */}
        <iframe
      style={{minHeight:300,flex:2,height:'100vw',maxHeight:360,width:'100%',maxWidth:700,minWidth:360,}}
         src={`https://www.youtube.com/embed/ODR4gVihC7A`+'?rel=0&autoplay=1'}
         frameBorder="0"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
         allowFullScreen
         title="Embedded youtube"
       /> 
       <div style={{flex:1,padding:10,minWidth:300}}>

        <h4 style={{fontSize:20}}>Konnect Beyond Limits: A Story of Connecting Africa</h4>
        <p style={{fontSize:14}}>Join Ahadi on an awe-inspiring journey to connect Africa! Three years ago, they set out to make a difference in the lives of underserved communities in Kenya by providing affordable and high-quality internet through Konnect Internet.
The Ahadi team had to overcome numerous hurdles; connect Africa, but their unwavering determination and hard work paid off. COVID-19 was no match for them, as they continued to deliver their exceptional service to the people of Githurai 44, and their success has been nothing short of phenomenal. 
With a dream of connect Africa; they've connected over 4000 buildings in 8 communities in Nairobi and have amassed almost half a million registered users. </p>
       </div>
       </div>
       {/* <YoutubeLIke videoId="ODR4gVihC7A" apiKey="AIzaSyD9Y0EXYToLc7amzpVEXzeYbP9nB_FJvSA" />, */}
           <br/>
       <div className="g-ytsubscribe" data-channelid="UCqUboAWearcNSPGVduir8zA" data-layout="full"  data-count="default"></div>
       {/* <div id="youtube-subscribe-button" /> */} 
       {/* (View Channel) */}
   
   <KonnectYoutube/>
   <div className="g-ytsubscribe" data-channelid="UCeRYAlSaZv4u3Uini1sb-Aw" data-layout="full" data-count="default"></div>
   <KonnectJikoni/>

   <div className="g-ytsubscribe" data-channelid="UCpFTG7fVcNIV3F3OytvC2QA" data-layout="full"  data-count="default"><br/>
   
   
   </div><br/><br/>
   <h5><a href='https://www.tiktok.com/@konnect.life'  target="_blank" rel="noopener noreferrer">
    
    <img src={'../images/tiktok2.png'} style={{width:50,margin:5,marginLeft:0}} />
    Our Tiktok Videos</a></h5>  </div>}
   <VerticalVideos setShow={setShow} /> 
 
   
   </div>

  );
};

export default Videos;